import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';
import { convertUrlToDomainFormat } from '../../utils/urlToDomain';

/**
 * The `DnsLookup` component is responsible for rendering a DNS lookup tool interface
 * within a larger web application. It allows users to input a domain name, select a DNS
 * server, and choose a DNS record type for lookup.
 *
 * The component leverages the Material-UI framework for styling and form elements, and it
 * uses Yup for form schema validation. Users can select from various DNS record types and
 * DNS servers for querying domain information. The component also displays the results of
 * the DNS lookup in a user-friendly format.
 *
 *  * All rights Reseverd | Arhamsoft Pvt @2023
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `DnsLookup` component.
 */
function DnsLookup() {
    /**
     * Design a schema for creating `DnsLookup` with form validations.
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     */
    const schema = yup.object().shape({
        domain: yup
            .string()
            .required('Domain is Required')
            // .url('Invalid URL Format. Please enter a valid URL in the following format: https://example.com.')
            // .test('no-trailing-slash', 'URL should not end with a slash ("/")', (value) => {
            //     if (value && value.endsWith('/')) {
            //         return false;
            //     }
            //     return true;
            // }),
    });

    // Initialization and state management
    const authToken = JSON.parse(localStorage.getItem('token'));
    const url = useLocation();
    const [records, setRecords] = useState([]);
    const path = url.pathname;
    const [loader, setLoader] = useState(false);
    const [selectedType, setSelectedType] = useState('ALL');
    const [domainUrl, setDomainUrl] = useState();
    const [dnsLookUp, setDnsLookUp] = useState();
    const [selectedServer, setSelected] = useState("Google");
    const [server, setServer] = useState(['Google', 'Cloudflare', 'OpenDNS', 'Quad9', 'Yandex', 'Authoritative']);
    const dnsTypes = ['ALL', 'A', 'AAAA', 'CNAME', 'MX', 'NS', 'PTR', 'SRV', 'SOA', 'TXT', 'CAA', 'DS', 'DNSKEY'];

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles DNS Lookup upon form submission
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for DNS Lookup.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     *
     */
    const handleSubmitForm = async (data) => {
        const { domain, server } = data;
        setLoader(true);
        setDnsLookUp(null)
        const DomainName = convertUrlToDomainFormat(domain)
        setDomainUrl(DomainName);
        try {
            const response = await api.get(
                `${ENV.appBaseUrl}/dns/dns-records?host=${DomainName}&type=${selectedType}&dnsServer=${server}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response?.data?.success) {
                setRecords(response?.data?.records);
                setDnsLookUp(response?.data?.records);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    const handleChange = (event) => {
        setSelectedType(event.target.value);
    };

     /**
     * Handles DNS Lookup and adds all DNS records to the clipboardData variable in text format.
     *
     * @param {object} records - The DNS records data for the given domain.
     * @param {string} domainUrl - The domain URL for which the records are retrieved.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 19 Oct, 2023
     */
    const clipboardData = [];
    const addDnsRecordsDataInTextForm = () => {
         // Iterate through each type in a specific order.
         const recordTypesToCheck = [ 'A', 'AAAA','CNAME','MX','NS','PTR','SRV','SOA','TXT','CAA','DS' ,'DNSKEY'];

       // Iterate through each record type
         recordTypesToCheck.forEach(type => {
             if (records[type] && records[type].length > 0) {
                 clipboardData.push(`${type} Records :\n`);
                 records[type].forEach((record, index) => {

                     // Handle specific logic for each DNS record type
                     if (type === 'A') {
                        const hostmasterText = type === 'A' ? '' : ` | Hostmaster: ${record.hostmaster || 'N/A'}`;
                         clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | ip: ${record || 'N/A'}${hostmasterText}`);
                     }

                     if (type === 'AAAA') {
                         const hostmasterText = type === 'AAAA' ? '' : ` | Hostmaster: ${record.hostmaster || 'N/A'}`;
                         clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | ipAddress: ${record || 'N/A'}${hostmasterText}`);
                     }

                     if (type === 'MX' ) {
                         clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | mx: ${record.exchange || 'N/A'} | priority: ${record.priority || 'N/A'} `);
                     }

                     if (type === 'NS') {
                         clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | value: ${record || 'N/A'}`);
                     }

                     if (type === 'PTR') {
                         clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | Address: ${record || 'N/A'}`);
                     }

                     if (type === 'SRV') {
                        clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | Address: ${record || 'N/A'}`);
                     }

                     if (type === 'SOA') {
                         clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | mname: ${record.nsname || 'N/A'} | rname: ${record.hostmaster || 'N/A'}`);
                     }

                     if (type === 'TXT' ) {
                          clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | value: ${record || 'N/A'}`);
                     }

                     if (type === 'CAA' ) {
                          clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | Record: ${record || 'N/A'}`);
                     }

                     if (type === 'CNAME' ) {
                        clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | Address: ${record || 'N/A'}`);
                     }

                     if (type === 'DS') {
                          clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} | key_tag: ${record.key_tag || 'N/A'}| Algorithem: ${record.algorithm || 'N/A'} | Digest_type: ${record.digest_type || 'N/A'}| Digest: ${record.digest || 'N/A'}`);

                     } if (type === 'DNSKEY') {
                         clipboardData.push(`[${index}] Name: ${domainUrl || 'N/A'} | ttl: ${record.minttl || 'N/A'} |  Algorithum: ${record.algorithm   || 'N/A' } |  Protocole: ${record.protocol || 'N/A' } |  Flag: ${record.flag || 'N/A' } |  keyid: ${record.key_id || 'N/A' }|  key: ${record.key || 'N/A' }`);
                     }
                 });
                 // Add a separator to clipboardData for the next record type
                 clipboardData.push('\n------------------\n');
             } else {
                 clipboardData.push(`${type} Records: None\n\n------------------\n`);
             }
         });
    }

    /**
     * Handles DNS Lookup and copies all records to the clipboard upon form submission.
     *
     * @param {object} e - The event object from the form submission.
     * @param {object} records - The DNS records data for the given domain.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 19 Oct, 2023
     *
     * Update 1 (October 25, 2023): Implemented efficient code for copying records to the clipboard.
     */
    const  copyToClipboard = (e) => {
        e.preventDefault();
        addDnsRecordsDataInTextForm()
        // Check if there are DNS records in the clipboardData to copy
        if (clipboardData.length > 0) {
            const textToCopy = clipboardData.join('\n');
            window.navigator.clipboard.writeText(textToCopy)
            toast.success("Copied To the Clipboard.")
        } else {
            toast.error('No Copied To the Clipboard.');
        }
    }

    /**
     * Generates a text file containing DNS records and triggers a download.
     *
     * @param {object} records - The DNS records data for the given domain.
     * @return {void}
     *
     *  Author: Muhammad Rooman
     *   Date: 19 Oct, 2023
    */
    const generateTextFile = (event) => {
        if (event) {
            event.preventDefault();
          }
        addDnsRecordsDataInTextForm();
        // Create a text string by joining clipboardData with line breaks
        const textToCopy = clipboardData.join('\n');
        // Create a Blob containing the text
        const blob = new Blob([textToCopy], { type: 'text/plain' });
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${domainUrl}-records.txt`;// Set the file name

        // Programmatically trigger the click event to download the file
        a.click();

        // Clean up by revoking the URL
        URL.revokeObjectURL(url);
    }


    /**
     * Handles DNS Lookup and adds all DNS records to the clipboardData variable in  format of Markdown..
     *
     * @param {object} records - The DNS records data for the given domain.
     * @return {void}
     *
     * Author: Zain Ashraf
     * Date: 20 Oct, 2023
     */
    const clipboardDataMarkdown = [];
    const addDnsRecordsData = () => {

      // Iterate through each type in a specific order.
         const recordTypesToCheck = [ 'A', 'AAAA','CNAME','MX','NS','PTR','SRV','SOA','TXT','CAA','DS' ,'DNSKEY'];

       // Iterate through each record type
         recordTypesToCheck.forEach(type => {
             if (records[type] && records[type].length > 0) {
                 clipboardDataMarkdown.push(`# ${type} Records\n`);
                 records[type].forEach((record, index) => {

                     // Handle specific logic for each DNS record type
                     if (type === 'A') {
                        const hostmasterText = type === 'A' ? '' : ` | Hostmaster: ${record.hostmaster || 'N/A'}`;
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'}${hostmasterText} |`);
                     }

                     if (type === 'AAAA') {
                         const hostmasterText = type === 'AAAA' ? '' : ` | Hostmaster: ${record.hostmaster || 'N/A'}`;
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'}${hostmasterText} |`);
                     }

                     if (type === 'MX' ) {
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Address | Preferences |`)
                             clipboardDataMarkdown.push(`|------|-----|---------|-------------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record.exchange || 'N/A'} | ${record.priority || 'N/A'} |`);
                     }

                     if (type === 'NS') {
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'} |`);
                     }

                     if (type === 'PTR') {
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'} |`);
                     }

                     if (type === 'SRV') {
                          if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'} |`);
                     }

                     if (type === 'SOA') {
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Mname | Rname |`)
                             clipboardDataMarkdown.push(`|------|-----|-------|-------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record.nsname || 'N/A'} | ${record.hostmaster || 'N/A'} |`);
                     }

                     if (type === 'TXT' ) {
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'} |`);
                     }

                     if (type === 'CAA' ) {
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'} |`);
                     }

                     if (type === 'CNAME' ) {
                         if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Data |`)
                             clipboardDataMarkdown.push(`|------|-----|------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record || 'N/A'} |`);
                     }

                     if (type === 'DS') {
                        if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Key Tag | Algorithm | Digest Type | Digest |`)
                             clipboardDataMarkdown.push(`|------|-----|---------|-----------|-------------|--------|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} | ${record.keytag || 'N/A'}| ${record.algorithm || 'N/A'} | ${record.digesttype || 'N/A'}| ${record.digest || 'N/A'} |`);

                     } if (type === 'DNSKEY') {
                        if (index === 0) {
                             clipboardDataMarkdown.push(`| Name | TTL | Algorithm | Protocol | Flag | KeyId | Key |`)
                             clipboardDataMarkdown.push(`|------|-----|-----------|----------|------|-------|-----|`)
                         }
                         clipboardDataMarkdown.push(`| ${domainUrl || 'N/A'} | ${record.minttl || 'N/A'} |  ${record.algorithm  || 'N/A' } | ${record.protocol || 'N/A' } | ${record.flag || 'N/A' } | ${record.keyid || 'N/A' }| ${record.key || 'N/A' } |`);
                     }
                 });
                 clipboardDataMarkdown.push('');
             } else {
                 clipboardDataMarkdown.push(`# ${type} Records: \n\n No records present.\n\n------------------\n`);
             }
         });
    }

  /**
 * Handles DNS lookup and copies all records to the clipboard in Markdown format upon form submission.
 *
 * @param {object} e - The event object from the form submission.
 * @param {object} records - The DNS records data for the given domain.
 * @return {void}
 *
 * Author: Zain Ashraf
 * Date: October 20, 2023
 *
 * Update 1 (Muhammad Rooman, October 25, 2023): Implemented efficient code for copying records to the clipboard.
 */
    const copyToClipboardMarkdown = (e) => {
        if (e) {
            e.preventDefault();
        }
        addDnsRecordsData(records)
        // Check if there are DNS records in the clipboardDataMarkdown to copy
        if (clipboardDataMarkdown.length > 0) {
            const textToCopy = clipboardDataMarkdown.join('\n');
            window.navigator.clipboard.writeText(textToCopy)
            toast.success("Copied To the Clipboard.")
        } else {
                toast.error('No Copied To the Clipboard.');
        }
    }

    /**
     * Generates a text file in format of Markdown containing DNS records and triggers a download.
     *
     * @param {object} records - The DNS records data for the given domain.
     * @return {void}
     *
     *  Author: Zain Ashraf
     *   Date: 20 Oct, 2023
    */
    const generateTextFileMarkdown = (event) => {
        if (event) {
            event.preventDefault();
          }
          addDnsRecordsData()
         // Create a text string by joining clipboardDataMarkdown with line breaks
         const textToDownload = clipboardDataMarkdown.join('\n');
         // Create a Blob containing the text
         const blob = new Blob([textToDownload], { type: 'text/plain' });
         // Create a URL for the Blob
         const url = URL.createObjectURL(blob);
         const a = document.createElement('a');
         a.href = url;
         a.download = `${domainUrl}-records.txt`;// Set the file name

         // Programmatically trigger the click event to download the file
         a.click();

         // Clean up by revoking the URL
         URL.revokeObjectURL(url);
    }

    /**
     * Renders a Material-UI CircularProgress spinner when the loader is active.
     *
     * @returns {JSX.Element} CircularProgress spinner if loader is active, otherwise null.
     *
     * Author: Ali Haider
     * Date: 4 Oct, 2023
     */
    if (loader) {
        return (
            <div className="spinner-wrapper">
                <CircularProgress value={100} />
            </div>
        );
    }

    const  onscroll=(id)=> {
        const section2 = document.getElementById(`section${id}`);
        if (section2) {
          const offset = section2.getBoundingClientRect().top + window.scrollY - 150;
          window.scrollTo({
            top: offset,
            behavior: 'smooth'
          });
        }
      }

/**
 * Renders an Accordion for each DNS record type with its details.
 *
 * @param {string} type - The DNS record type (e.g., 'A', 'AAAA', 'CNAME', etc.).
 * @param {Array} records - The array of DNS records for the given type.
 * @returns {JSX.Element} Accordion with DNS record details or 'No Records Found' if no records exist.
 *
 * Author: Ali Haider
 * Date: 17 Oct, 2023
 */
    function renderRecordType(type, records) {
        return (
            <Typography>

                {type === 'A' && records?.length > 0 && (<div className="accordion-body">
                    <div className="record_headings">
                        <strong>Type</strong>
                        <strong>Domain Name</strong>
                        <strong>TTL</strong>
                        <strong>Address</strong>
                    </div>
                    {records?.map((record, index) => (
                        <div className="record_results blacklist-required" key={index}>
                            <span>{type}</span>
                            <span>{domainUrl || "N/A"}</span>
                            <span>{record?.ttl || "N/A"}</span>
                            <div>
                                {record || "N/A"}

                            </div>
                        </div>
                    ))}

                </div>
                )}

                {type === 'AAAA' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Address</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <div>
                                    {record || "N/A"}

                                </div>
                            </div>
                        ))
                        }
                    </div>
                )}
                {type === 'CNAME' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Address</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <div>
                                    {record || "N/A"}
                                </div>
                            </div>
                        ))
                        }
                    </div>
                )}
                {type === 'MX' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Prefernces</strong>
                            <strong>Address</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <span>{record?.priority || "N/A"}</span>
                                <div>
                                    {record?.exchange || "N/A"}

                                </div>
                            </div>
                        ))
                        }
                    </div>
                )}
                {type === 'NS' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Canonical Name</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <div>
                                    {record || "N/A"}

                                </div>
                            </div>
                        ))
                        }
                    </div>
                )}

                {type === 'SOA' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Primary NS</strong>
                            <strong>Responsible Email</strong>

                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.minttl || "N/A"}</span>
                                <span>
                                    {record?.nsname || "N/A"}

                                </span>
                                <span>
                                    {record?.hostmaster || "N/A"}
                                </span>
                            </div>
                        ))
                        }
                    </div>
                )}
                {type === 'TXT' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Record</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <div>
                                    {record[0] || "N/A"}

                                </div>
                            </div>
                        ))
                        }
                    </div>
                )}
                {type === 'CAA' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Record</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <div>
                                    {record || "N/A"}

                                </div>

                            </div>
                        ))
                        }
                    </div>
                )}
                {type === 'DS' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Key Tag</strong>
                            <strong>Algorithm</strong>
                            <strong>Digest Type</strong>
                            <strong>Digest</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <span>
                                    {record?.key_tag || "N/A"}
                                </span>
                                <span>
                                    {record?.algorithm || "N/A"}
                                </span>  <span>
                                    {record?.digest_type || "N/A"}
                                </span>
                                <span>
                                    {record?.digest || "N/A"}
                                </span>

                            </div>
                        ))
                        }
                    </div>
                )}
                {type === 'DNSKEY' && records?.length > 0 && (
                    <div className="accordion-body">
                        <div className="record_headings">
                            <strong>Type</strong>
                            <strong>Domain Name</strong>
                            <strong>TTL</strong>
                            <strong>Algorithm</strong>
                            <strong>Protocol</strong>
                            <strong>Flag</strong>
                            <strong>Key ID</strong>
                            <strong>Key</strong>
                        </div>
                        {records?.map((record, index) => (
                            <div className="record_results blacklist-required" key={index}>
                                <span>{type}</span>
                                <span>{domainUrl || "N/A"}</span>
                                <span>{record?.ttl || "N/A"}</span>
                                <span>
                                    {record?.algorithm || "N/A"}
                                </span>
                                <span>
                                    {record?.protocol || "N/A"}
                                </span>  <span>
                                    {record?.flag || "N/A"}
                                </span>  <span>
                                    {record?.key_id || "N/A"}
                                </span>
                                <span>
                                    {record?.key || "N/A"}
                                </span>
                            </div>
                        ))
                        }
                    </div>
                )}
            </Typography>
        );
    }

    return (
        <div className="padding_px">
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom className="ml-10">
                            <Link to="/dashboard/app">
                                <Button className="back_btn" variant="contained">
                                    <Iconify icon="bi:arrow-left" />
                                </Button>
                            </Link>
                            DNS Lookup
                            <div className="mt-10">
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to="/dashboard/app" className="domain_bread">
                                        Dashboard
                                    </Link>
                                    <Link to="/dashboard/dns-lookup" className="domain_bread">
                                        DNS Lookup
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </Typography>
                        <Card sx={{ p: 2 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <TextField
                                            {...register('domain')}
                                            id="outlined-basic"
                                            label="www.example.com"
                                            variant="outlined"
                                            type="url"
                                            fullWidth
                                            error={!!errors?.domain}
                                            helperText={errors.domain?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl sx={{ minWidth: '100%' }}>
                                            <Select
                                                {...register('server')}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Choose Server"
                                                defaultValue={selectedServer}
                                                fullWidth
                                                onChange={(event) => setSelected(event.target.value)}
                                            >
                                                {server?.map((type, index) => {
                                                    return (
                                                        <MenuItem key={index} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <div className="col-sm-12">
                                <div className="btn_toggle flex-wrap" role="group" data-bs-toggle="buttons">
                                    {dnsTypes?.map((type) => (

                                        <div key={type} className='mb-50'>


                                            <input
                                                {...register('type')}
                                                id={type}
                                                type="radio"
                                                className="btn-check"
                                                name="options"
                                                value={type}
                                                checked={selectedType === type}
                                                onChange={handleChange}
                                            />
                                            <label
                                                htmlFor={type}
                                                className="btn btn-info border-light rounded radio_custom"
                                            >
                                                {type}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '16px',
                                    marginRight: '22px',
                                }}
                            >
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" type="submit" color="primary">
                                        Lookup DNS
                                    </Button>
                                </Stack>
                            </div>
                        </Card>
                        <br />
                    </Grid>
                </Grid>
            </form>
            <Tools dnsLookUpPathName={path} />
            {dnsLookUp && <Card>
                <CardContent>
                    <div className="flex justify-end">
                        <div className=" results-left-section">
                            <span className="h5 text-dark">Result for: </span>
                            <h2 className="h5 mt-2 text-uppercase text_green text-start d-inline-block"> {domainUrl}</h2>
                        </div>
                        <div className=" results-right-section">
                            <div className="flex items-center gap-3 custom-download-btn">
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                <span className="download__btn">
                                                    Download Records
                                                    <img src="/assets/icons/download.svg" alt="download" />
                                                </span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <div
                                                    className="my-2 px-0 row p-2 collapse markdon_wrap"
                                                    id="d_download"
                                                >
                                                    <div className="mardown_btn">
                                                        <Link
                                                            to=""
                                                            className="btn btn-info border-0 my-1 records d-block"
                                                            data-type="d-md"
                                                            onClick={(event) => generateTextFileMarkdown(event)}
                                                        >
                                                            Download In Markdown
                                                        </Link>
                                                    </div>
                                                    <div className="mardown_btn">
                                                    <Link
                                                        to=""
                                                        className="btn btn-info border-0 my-1 records d-block"
                                                        data-type="c-md"
                                                        onClick={(e) => {copyToClipboardMarkdown(e); }}
                                                    >
                                                        Copy In Markdown
                                                    </Link>

                                                    </div>
                                                    <div className="mardown_btn">
                                                        <Link
                                                            to=""
                                                            className="btn btn-info border-0 my-1 records d-block"
                                                            data-type="d-txt"
                                                            onClick={(event) => generateTextFile(event)}
                                                        >
                                                            Download In Text Form
                                                        </Link>
                                                    </div>
                                                    <div className="mardown_btn">
                                                        <Link
                                                            to=""
                                                            className="btn btn-info border-0 my-1 records d-block"
                                                            data-type="c-txt"
                                                            onClick={(e) => copyToClipboard(e)} // Call copyToClipboard with the records
                                                        >
                                                            Copy In Text Form
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="jump_to">
                        <div className="jump-wrapper">
                            <p className="jump-records">
                                <b>Jump to:</b>
                                <span>
                                    <button onClick={() => onscroll(0)}  className="jump-link" type="button">
                                        A Records
                                    </button>
                                </span>
                                <span>
                                <button onClick={() => onscroll(1)}  className="jump-link" type="button">
                                AAAA Records
                                    </button>
                                    {/* <a href="#heading_AAAA" className="jump-link" >
                                        AAAA Records
                                    </a> */}
                                </span>
                                <span>
                                    <button onClick={() => onscroll(2)}  className="jump-link"  type="button">
                                        CNAME Records
                                    </button>
                                </span>
                                <span>
                                    <button onClick={() => onscroll(3)} className="jump-link"  type="button">
                                        MX Records
                                    </button>
                                </span>
                                <span>
                                    <button onClick={() => onscroll(4)} className="jump-link"  type="button">
                                        NS Records
                                    </button>
                                </span>
                                <span>
                                    <button onClick={() => onscroll(5)} className="jump-link" type="button">
                                        PTR Records
                                    </button>
                                </span>
                                <span><button onClick={() => onscroll(6)} className="jump-link" type="button">SRV Records</button></span>
                                <span>
                                    <button onClick={() => onscroll(7)} className="jump-link"  type="button">
                                        SOA Records
                                    </button>
                                </span>
                                <span>
                                    <button onClick={() => onscroll(8)} className="jump-link" type="button">
                                        TXT Records
                                    </button>
                                </span>
                                <span>
                                    <button onClick={() => onscroll(9)} className="jump-link"  type="button">
                                        CAA Records
                                    </button>
                                </span>
                                <span>
                                    <button onClick={() => onscroll(10)} className="jump-link"  type="button">
                                        DS Records
                                    </button>
                                </span>
                                <span>
                                    <button  onClick={() => onscroll(11)} className="jump-link"  type="button">
                                        DNSKEY Records
                                    </button>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="detail_dns_lookup">
                        {dnsLookUp &&
                            Object.entries(dnsLookUp)?.map(([type, records], index) => {
                                if (!records || (Array.isArray(records) && records.length === 0)) {
                                    return null;
                                }
                                return <>
                                    <Accordion key={type} defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                <span className="accord_btn" id={`section${index}`} >{type}</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {renderRecordType(type, records)}
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            })}
                    </div>
                </CardContent>
            </Card>}
        </div>
    );
}

export default DnsLookup;

