import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';
import { urlToDomainFormat } from '../../utils/urlToDomain';

/**
 * The `IpLocationFinder` component provides a user interface for looking up the location
 * and details of an IP address. Users can enter an IP address or domain name, and the
 * component retrieves information about the IP's country, state, city, latitude, longitude,
 * ISP, and more. This information is displayed using two different services: IPInfo.io and DB IP.
 *
 * The component integrates with Material-UI for styling and uses iframes to display maps
 * with the location of the IP address.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `IpLocationFinder` component.
 */
const IpLocationFinder = () => {
    /**
     * Design a schema for creating `IpLocationFinder` with form validations.
     *
     * Author: Muhammad Rooman
     * Date: 9 Oct, 2023
     */
    const schema = yup.object().shape({
        ipAddress: yup
            .string()
            .required('Domain is Required')
            .matches(
                /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                'Invalid URL Format. Please Enter a Valid Domain in The Following Format: https://example.com'
            ),
    });

    // Initialization and state management
    const url = useLocation();
    const [ipLocation, setIpLocation] = useState([]);
    const authToken = JSON.parse(localStorage.getItem('token'));
    const [loader, setLoader] = useState(false);
    const ipLocationPath = url.pathname;

    // They are part of the schema, form management and validation logic for IpLocationFinder
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles IpLocationFinder upon form submission
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for IpLocationFinder.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 9 Oct, 2023
     *
     */
    const handleSubmitForm = async (data) => {
        setLoader(true);
        const { ipAddress } = data;
        const DomainName = urlToDomainFormat(ipAddress)
        try {
            const response = await api.get(`${ENV.appBaseUrl}/dns/ip-location?domain=${DomainName}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
                },
            });
            if (response?.data?.success) {
                setIpLocation(response.data.results);
                toast.success(response.data?.message);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    return (
        <>
            <div className="padding_px">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom className="ml-10">
                            <Link to="/dashboard">
                                <Button className="back_btn" variant="contained">
                                    <Iconify icon="bi:arrow-left" />
                                </Button>
                            </Link>
                            IP Address Lookup
                            <div className="mt-10">
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to="/dashboard" className="domain_bread">
                                        Dashboard
                                    </Link>
                                    <Link to="/dashboard/ip-location" className="domain_bread">
                                        IP Address Lookup
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </Typography>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <Card sx={{ p: 2 }}>
                                <CardContent>
                                    <TextField
                                        {...register('ipAddress')}
                                        id="outlined-basic"
                                        label="Domain/IP"
                                        placeholder="Enter any IP Address or URL"
                                        variant="outlined"
                                        sx={{ marginRight: '8px', width: '100%' }}
                                        type="text"
                                        error={!!errors?.ipAddress}
                                        helperText={errors.ipAddress?.message}
                                    />
                                </CardContent>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '16px',
                                        marginRight: '22px',
                                    }}
                                >
                                    <Stack spacing={2} direction="row">
                                        <Button variant="contained" type="submit" color="primary">
                                            IP Lookup
                                        </Button>
                                    </Stack>
                                </div>
                            </Card>
                            <br />
                        </form>
                    </Grid>
                </Grid>
                <Tools ipLocationPathName={ipLocationPath} />
                {loader ? (
                    <div
                        className="spinner"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '160px' }}
                    >
                        <CircularProgress value={100} />
                    </div>
                ) : (
                    ipLocation?.map((item, index) => {
                        // Extract latitude and longitude
                        const locArray = item.data.loc?.split(',') || ['', ''];
                        const [latitude, longitude] = locArray;

                        return (
                            <div key={`ipl_results_dbip_${index}`}>
                                 {item.service === "IPInfo.io" && (
                            <div className="box_container" >
                                <div className="row">
                                    <div className="px-1 px-xl-2 col-lg-6 pt-2">
                                        <h2 className="ip_heading h5 text-primary">
                                            <a
                                                className="text-dark"
                                                href="https://dnschecker.org/out/dbip"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {item.service}
                                            </a>
                                        </h2>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <table className="table w-full">
                                                    <tbody>
                                                        <tr>
                                                            <th className="text-start">
                                                                <i className="icon-colored icon-ip-colored icon-lg" />
                                                                IP:
                                                            </th>
                                                            <td className="text-center">{item.data.ip || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-start">
                                                                <i className="icon-colored icon-globe-colored icon-lg" />
                                                                Country:
                                                            </th>
                                                            <td className="text-center pak_wraper">
                                                              
                                                                {item.data.country || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-start">
                                                                <i className="icon-colored icon-state icon-lg" /> State:
                                                            </th>
                                                            <td className="text-center">{item.data.region || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-start">
                                                                <i className="icon-colored icon-city icon-lg" /> City:
                                                            </th>
                                                            <td className="text-center"> {item.data.city || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-start">
                                                                <i className="icon-colored icon-latitude icon-lg" />
                                                                Latitude:
                                                            </th>
                                                            <td className="text-center">{latitude || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-start">
                                                                <i className="icon-colored icon-longitude icon-lg" />
                                                                Longitude:
                                                            </th>
                                                            <td className="text-center">{longitude || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-start">
                                                                <i className="icon icon-isp icon-dark icon-lg" /> ISP:
                                                            </th>
                                                            <td className="text-center">{item.data.org || "N/A"}</td>
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                                <div className="small float-start d-inline-block pe-1">
                                                    IP Location Services by:
                                                    <a
                                                        href="https://dnschecker.org/out/dbip"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        {item.service || "N/A"}
                                                    </a>
                                                </div>
                                                <div className="small float-end d-inline-block pe-1">
                                                    Updated: Real-time
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-1 px-xl-2 col-lg-6 float-start pt-2">
                                        <div className="rounded-3 overflow-hidden">
                                            <iframe
                                                className="ip_map"
                                                id="ipl_dbip_map"
                                                title="IP Location Map"
                                                src={`https://maps.google.com/maps?hl=en&z=14&output=embed&q=${item?.data?.loc || ""}`}
                                                style={{ height: '410px', marginBottom: '-5px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                             )}
                             {item.service === "DB IP" && (
                             <div className="box_container">
                             <div className="row">
                                 <div className="px-1 px-xl-2 col-lg-6 pt-2">
                                     <h2 className="ip_heading h5 text-primary">
                                         <a
                                             className="text-dark"
                                             href="https://dnschecker.org/out/dbip"
                                             rel="noopener noreferrer"
                                             target="_blank"
                                         >
                                             {item.service || "N/A"}
                                         </a>
                                     </h2>
                                     <div className="row">
                                         <div className="col-lg-12">
                                             <table className="table w-full">
                                                 <tbody>
                                                     <tr>
                                                         <th className="text-start">
                                                             <i className="icon-colored icon-ip-colored icon-lg" />
                                                             IP:
                                                         </th>
                                                         <td className="text-center">{item.data?.ipAddress || "N/A"}</td>
                                                     </tr>
                                                     <tr>
                                                         <th className="text-start">
                                                             <i className="icon-colored icon-globe-colored icon-lg" />
                                                             Country:
                                                         </th>
                                                         <td className="text-center pak_wraper">
                                                             {/* <img
                                                                 width="19px"
                                                                 style={{
                                                                     border: '1px solid #ccc',
                                                                     verticalAlign: 'text-bottom',
                                                                 }}
                                                                 src="https://dnschecker.org/themes/common/images/flags/svg/pk.svg"
                                                                 alt="Pakistan"
                                                             />{' '} */}
                                                             {item.data.countryName || "N/A"}
                                                         </td>
                                                     </tr>
                                                     <tr>
                                                         <th className="text-start">
                                                             <i className="icon-colored icon-state icon-lg" /> State:
                                                         </th>
                                                         <td className="text-center">{item.data.stateProv || "N/A"}</td>
                                                     </tr>
                                                     <tr>
                                                         <th className="text-start">
                                                             <i className="icon-colored icon-city icon-lg" /> City:
                                                         </th>
                                                         <td className="text-center"> {item.data.city || "N/A"}</td>
                                                     </tr>
                                                     <tr>
                                                         <th className="text-start">
                                                             <i className="icon-colored icon-latitude icon-lg" />
                                                             Latitude:
                                                         </th>
                                                         <td className="text-center">{latitude || "N/A"}</td>
                                                     </tr>
                                                     <tr>
                                                         <th className="text-start">
                                                             <i className="icon-colored icon-longitude icon-lg" />
                                                             Longitude:
                                                         </th>
                                                         <td className="text-center">{longitude || "N/A"}</td>
                                                     </tr>
                                                     <tr>
                                                         <th className="text-start">
                                                             <i className="icon icon-isp icon-dark icon-lg" /> ISP:
                                                         </th>
                                                         <td className="text-center">{item.data.organization || "N/A"}</td>
                                                     </tr>
                                                     
                                                 </tbody>
                                             </table>
                                             <div className="small float-start d-inline-block pe-1">
                                                 IP Location Services by:
                                                 <a
                                                     href="https://dnschecker.org/out/dbip"
                                                     rel="noopener noreferrer"
                                                     target="_blank"
                                                 >
                                                     {item.service || "N/A"}
                                                 </a>
                                             </div>
                                             <div className="small float-end d-inline-block pe-1">
                                                 Updated: Real-time
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div className="px-1 px-xl-2 col-lg-6 float-start pt-2">
                                     <div className="rounded-3 overflow-hidden">
                                         <iframe
                                             className="ip_map"
                                             id="ipl_dbip_map"
                                             title="IP Location Map"
                                             src={`https://maps.google.com/maps?hl=en&z=14&output=embed&q=${item?.data?.loc || ""}`}
                                             style={{ height: '410px', marginBottom: '-5px' }}
                                         />
                                     </div>
                                 </div>
                             </div>
                         </div>
                         )}
                         </div>
                        );
                    })
                )}
            </div>
        </>
    );
};

export default IpLocationFinder;
