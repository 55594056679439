import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';
import { convertUrlToDomainFormat } from '../../utils/urlToDomain';

/**
 * The `PageRank` component allows users to check the Page Rank of a given domain or URL. Users can input a URL,
 * and the component displays the Page Rank along with a visual representation in the form of a progress bar and emojis.
 *
 * This component provides an intuitive user interface with Material-UI styling, and it allows users to easily check the
 * Page Rank of a specific website. Users can navigate back to the dashboard from this component.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `PageRank` component.
 */
const PageRank = () => {
    /**
     * Design a schema for creating `PageRank` with form validations.
     *
     * Author: Muhammad Rooman.
     * Date: 6 Oct, 2023.
     */
    const schema = yup.object().shape({
        domain: yup.string().required('Domain is Required'),
        // .test('No-Trailing-Slash', 'URL Should Not End With a Slash ("/")', (value) => {
        //     if (value && value.endsWith('/')) {
        //         return false;
        //     }
        //     return true;
        // }),
    });

    // Initialization and state management.
    const [pagRankData, setPageRankData] = useState([]);
    const authToken = JSON.parse(localStorage.getItem('token'));
    const [loader, setLoader] = useState(false);
    const url = useLocation();
    const pageRankPath = url.pathname;

    // They are part of the schema, form management and validation logic for `PageRank`.
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles `PageRank` upon form submission.
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for `PageRank`.
     * @return {void}
     *
     * Author: Muhammad Rooman.
     * Date: 6 Oct, 2023.
     *
     * Update 1 (13 Oct, 2023): Convert the input URL to a domain format.
     *
     */
    const handleSubmitForm = async (data) => {
        setLoader(true);
        const { domain } = data;
        const DomainName = domain;
        try {
            const response = await api.get(`${ENV.appBaseUrl}/dns/page-rank?domain=${DomainName}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response?.data?.success) {
                setPageRankData(response.data.pageRank);
                toast.success(response.data?.message);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    return (
        <div className="padding_px">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" gutterBottom className="ml-10">
                        <Link to="/dashboard/app">
                            <Button className="back_btn" variant="contained">
                                <Iconify icon="bi:arrow-left" />
                            </Button>
                        </Link>
                        Page Rank
                        <div className="mt-10">
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/dashboard/app" className="domain_bread">
                                    Dashboard
                                </Link>
                                <Link to="/dashboard/pagerank" className="domain_bread">
                                    Page Rank
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Card sx={{ p: 2 }}>
                            <CardContent>
                                <TextField
                                    {...register('domain')}
                                    id="outlined-basic"
                                    label="Enter Domain to Check Page Rank"
                                    placeholder="Enter URL"
                                    variant="outlined"
                                    sx={{ marginRight: '8px', width: '100%' }}
                                    type="text"
                                    error={!!errors?.domain}
                                    helperText={errors.domain?.message}
                                />
                            </CardContent>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '16px',
                                    marginRight: '22px',
                                }}
                            >
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" type="submit" color="primary">
                                        Check Page Rank
                                    </Button>
                                </Stack>
                            </div>
                        </Card>
                        <br />
                    </form>
                </Grid>
            </Grid>
            <Tools pageRankPathName={pageRankPath} />
            {loader ? (
                <div
                    className="spinner"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '160px' }}
                >
                    <CircularProgress value={100} />
                </div>
            ) : pagRankData?.length > 0 ? (
                <div className="box_container my-4" id="prc_results" style={{ minHeight: '145px' }}>
                    <h3 className="result_heading mb-3">{pagRankData[0]?.domain}</h3>
                    <div className="page_wrank">
                        <h4 className="rank_heading">Page Rank</h4>
                        <div className="progress_container">
                            <div className="emoji_wrank">
                                <span>
                                    <img src="/assets/icons/broken-emoji.svg" alt="broken-emoji" />
                                </span>
                                <span>
                                    <img src="/assets/icons/sad-emoji.svg" alt="broken-emoji" />
                                </span>

                                <span>
                                    <img src="/assets/icons/sad2-emoji.svg" alt="broken-emoji" />
                                </span>
                                <span>
                                    <img src="/assets/icons/sad2-emoji.svg" alt="broken-emoji" />
                                </span>
                                <span>
                                    <img src="/assets/icons/smile-emoji.svg" alt="broken-emoji" />
                                </span>

                                <span>
                                    <img src="/assets/icons/smile2-emoji.svg" alt="broken-emoji" />
                                </span>

                                <span>
                                    <img src="/assets/icons/happy-emoji.svg" alt="broken-emoji" />
                                </span>

                                <span>
                                    <img src="/assets/icons/happy2-emoji.svg" alt="broken-emoji" />
                                </span>

                                <span>
                                    <img src="/assets/icons/smart-emoji.svg" alt="broken-emoji" />
                                </span>
                                <span>
                                    <img src="/assets/icons/heart-emoji.svg" alt="broken-emoji" />
                                </span>
                                <span>
                                    <img src="/assets/icons/heart2-emoji.svg" alt="broken-emoji" />
                                </span>
                            </div>
                            <div className="progress">
                                <div className="bar" style={{ width: `${pagRankData[0]?.page_rank_integer * 11}%` }} />
                            </div>
                        </div>
                        <strong className="h2 rank_num">
                            <span style={{ color: '#FFB703' }}>{pagRankData[0]?.page_rank_integer}</span>{' '}
                            <span style={{ color: '#AFAFAF' }}>/</span> 10
                        </strong>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default PageRank;
