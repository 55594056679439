import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Breadcrumbs,
    Grid,
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';

/**
 * The `PortChecker` component provides a user interface for checking the status
 * of ports on a specified domain or IP address. Users can input a domain or IP,
 * select a port type, and list the ports they want to check. The component leverages
 * Material-UI for styling and form elements.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `PortChecker` component.
 */
const PortChecker = () => {
    /**
     * Design a schema for creating `PortChecker` with form validations.
     *
     * Author: Muhammad Rooman.
     * Date: 6 Oct, 2023.
     */
    const schema = yup.object().shape({
        host: yup
            .string()
            .required('Domain or IP is Required')
            .test('No-Trailing-Slash', 'URL Should Not End With a Slash ("/")', (value) => {
                if (value && value.endsWith('/')) {
                    return false;
                }
                return true;
            }),
        port: yup.string().required('Port  is Required'),
    });

    // Initialization and state management
    const authToken = JSON.parse(localStorage.getItem('token'));
    const [ipAddress, setIPAddress] = useState('');
    const [loader, setLoader] = useState(true);
    const [loadeData, setLoadeData] = useState(false);
    const url = useLocation();
    const portCheckerPath = url.pathname;
    const serverPorts = [
        '21',
        '22',
        '23',
        '25',
        '53',
        '80',
        '110',
        '137',
        '138',
        '139',
        '143',
        '443',
        '445',
        '548',
        '587',
        '993',
        '995',
        '1433',
        '1701',
        '1723',
        '3306',
        '5432',
        '8008',
        '8443',
    ];
    const gamePorts = [
        '666',
        '2302',
        '3453',
        '3724',
        '4000',
        '5154',
        '6112',
        '6113',
        '6114',
        '6115',
        '6116',
        '6117',
        '6118',
        '6119',
        '7777',
        '10093',
        '10094',
        '12203',
        '14567',
        '25565',
        '26000',
        '27015',
        '27910',
        '28000',
        '50000',
    ];
    const applicationPorts = [
        '515',
        '631',
        '3282',
        '3389',
        '5190',
        '5050',
        '4443',
        '1863',
        '6891',
        '1503',
        '5631',
        '5632',
        '5900',
        '6667',
    ];
    const P2pPorts = [
        '119',
        '375',
        '425',
        '1214',
        '412',
        '1412',
        '2412',
        '4661',
        '4662',
        '4665',
        '5500',
        '6346',
        '6881',
        '6882',
        '6883',
        '6884',
        '6885',
        '6886',
        '6887',
        '6888',
        '6889',
    ];
    const portTypeOptions = ['Custom Ports', 'Server Ports', 'Game Ports', 'Application Ports', 'P2P Ports'];
    const [portType, setPortType] = useState('Custom Ports');
    const [selectedPorts, setSelectedPorts] = useState([]);
    const [customPort, setCustomPort] = useState('');
    const [ports, setPorts] = useState([]);

    // They are part of the schema, form management and validation logic for `PortChecker`.
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles `PortChecker` upon form submission. This function is responsible for
     * updating the selected port type and determining which set of ports to display
     * based on the selected option.
     *
     * @param {object} data - The data containing the domain for `PortChecker`.
     *
     * Author: Muhammad Rooman.
     * Date: 13 Oct, 2023.
     */
    const handlePortTypeChange = (event) => {
        const selectedPortType = event.target.value;
        setPortType(selectedPortType);
        if (selectedPortType === 'Server Ports') {
            setSelectedPorts(serverPorts);
        }
        if (selectedPortType === 'Game Ports') {
            setSelectedPorts(gamePorts);
        }
        if (selectedPortType === 'Application Ports') {
            setSelectedPorts(applicationPorts);
        }
        if (selectedPortType === 'P2P Ports') {
            setSelectedPorts(P2pPorts);
        } else if (selectedPortType === 'Custom Ports') {
            setSelectedPorts([]);
            setCustomPort('');
        }
    };

    /**
     * Handles fetching the IP address and updating the component state.
     * Send a GET request to retrieve the IP address from an external API.
     *
     * Author: Muhammad Rooman.
     * Date: 13 Oct, 2023.
     */
    useEffect(() => {
        async function fetchIpAddress() {
            try {
                setLoader(true);
                const response = await fetch('https://api.ipify.org?format=json');
                if (!response.ok) {
                    throw new Error('Failed to fetch IP address');
                }
                const data = await response.json();
                setIPAddress(data.ip);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoader(false);
            }
        }
        fetchIpAddress();
    }, []);

    /**
     * Handles the submission of PortChecker data.
     * Sends a GET request to retrieve records for a given domain and specified ports.
     *
     * This function is designed to check each port one by one and make an API call for each port.
     *
     * @param {object} data - The data containing the domain and port information for PortChecker.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 9 Oct, 2023
     */
    const handleSubmitForm = async (data) => {
        setLoadeData(true);
        setPorts([]);
        const { host, port } = data;

        try {
            if (portType === 'Custom Ports' && customPort.trim() !== '') {
                // Split and map custom ports to individual promises for scanning.
                const customPortsArray = customPort.split(',').map((port) => port.trim());
                const customPortPromises = customPortsArray.map(async (customPortItem) => {
                    try {
                        // Send a GET request to scan the custom port for the given host.
                        const response = await api.get(
                            `${ENV.appBaseUrl}/dns/port-scanner?host=${host}&port=${customPortItem}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${authToken}`,
                                },
                            }
                        );
                        return { port: customPortItem, response };
                    } catch (error) {
                        return { port: customPortItem, error };
                    }
                });

                const customPortResults = await Promise.all(customPortPromises); // Wait for all API requests to complete concurrently and collect their results
                customPortResults.forEach(({ port, response }) => {
                    if (response?.data?.success) {
                        setPorts((prevPorts) => [...prevPorts, response.data.results[0].result]);
                    } else {
                        toast.error(response?.data?.message);
                    }
                });
            } else {
                // Map selected ports to individual promises for scanning.
                const apiCalls = selectedPorts.map(async (selectedPort) => {
                    try {
                        // Send a GET request to scan the selected port for the given host.
                        const response = await api.get(
                            `${ENV.appBaseUrl}/dns/port-scanner?host=${host}&port=${selectedPort}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${authToken}`,
                                },
                            }
                        );
                        return { port: selectedPort, response };
                    } catch (error) {
                        return { port: selectedPort, error };
                    }
                });

                const results = await Promise.all(apiCalls); // Wait for all API requests to complete concurrently and collect their results.
                results.forEach(({ port, response }) => {
                    if (response?.data?.success) {
                        setPorts((prevPorts) => [...prevPorts, response.data.results[0].result]);
                    } else {
                        toast.error(response?.data?.message);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadeData(false);
        }
    };

    /**
     * Renders a Material-UI CircularProgress spinner when the loader is true.
     *
     * @returns {JSX.Element} CircularProgress spinner if loader is true, otherwise null.
     *
     * Author: Muhammad Rooman
     * Date: 10 Oct, 2023
     */
    if (loader) {
        return (
            <div className="spinner-wrapper">
                <CircularProgress value={100} />
            </div>
        );
    }
    return (
        <div className="padding_px">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" gutterBottom className="ml-10">
                        <Link to="/dashboard">
                            <Button className="back_btn" variant="contained">
                                <Iconify icon="bi:arrow-left" />
                            </Button>
                        </Link>
                        Port Checker
                        <div className="mt-10">
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/dashboard" className="domain_bread">
                                    Dashboard
                                </Link>
                                <Link to="/dashboard/port-scanner" className="domain_bread">
                                    Port Checker
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Card sx={{ p: 2 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <TextField
                                            {...register('host')}
                                            defaultValue={ipAddress}
                                            id="outlined-basic"
                                            label="Domain / IP"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors?.host}
                                            helperText={errors.host?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl sx={{ minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-label">Port Type</InputLabel>
                                            <Select
                                                {...register('portType')}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Port Type"
                                                value={portType}
                                                onChange={handlePortTypeChange}
                                                fullWidth
                                            >
                                                {portTypeOptions.map((type, index) => {
                                                    return (
                                                        <MenuItem key={index} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <TextField
                                    {...register('port')}
                                    id="outlined-basic"
                                    label="Ports"
                                    variant="outlined"
                                    sx={{ marginRight: '8px', flex: 1 }}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    value={portType === 'Custom Ports' ? customPort : selectedPorts.join(', ')}
                                    onChange={(e) => {
                                        if (portType === 'Custom Ports') {
                                            setCustomPort(e.target.value);
                                        } else {
                                            setSelectedPorts(e.target.value.split(',').map((port) => port.trim()));
                                        }
                                    }}
                                    required
                                />
                            </CardContent>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '16px',
                                    marginRight: '22px',
                                }}
                            >
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" type="submit" color="primary">
                                        Check
                                    </Button>
                                </Stack>
                            </div>
                        </Card>
                        <br />
                    </form>
                </Grid>
            </Grid>
            <Tools portCheckerPathName={portCheckerPath} />
            {loadeData ? (
                <div
                    className="spinner"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '160px' }}
                >
                    <CircularProgress value={100} />
                </div>
            ) : ports?.length ? (
                <div className="box_container mt-4" id="ps_results">
                    <table className="table mb-0 w-full">
                        <thead>
                            <tr>
                                <th className="border border-0 text-14 fw_600 text-start">Title</th>
                                <th className="border border-0 text-14 fw_600 text-start">Port</th>
                                <th className="border border-0 text-14 fw_600 " style={{ width: '35%' }}>
                                    Result
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ports?.map((port) => {
                                return (
                                    <>
                                        <tr>
                                            <td className="text-14">{port?.name}</td>
                                            <td className=" text-14">{port?.port}</td>
                                            <td className="text-center text-14">
                                                <span
                                                    className={`w-100 d-inline-block rounded text-14 py-1 ${
                                                        port?.status === 'Timed-Out' ? 'bg_orange' : 'bg_green'
                                                    } text-dark time_out`}
                                                    id="ps_res_21"
                                                >
                                                    {port?.status}
                                                </span>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : null}
        </div>
    );
};

export default PortChecker;
