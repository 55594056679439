import React, { useState } from 'react'
import { Card, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'

/**
 * The `Tools` component represents a section of a user interface
 * that displays a collection of related tools or links. It is typically
 * used within a larger dashboard or web application to provide easy access
 * to various tools and functionalities.
 *
 * This component utilizes Material-UI's Card and CardContent components
 * to create a visually appealing layout. Inside the CardContent, it displays
 * a list of links, each associated with an icon and a title, providing a
 * user-friendly way to access specific tools or features.
 *
 * The links are implemented using React Router's Link component, which
 * allows for seamless navigation within the application when a link is clicked.
 *
 * All rights Reseverd | Arhamsoft Pvt @2023
 * 
 * @returns {JSX.Element} The JSX representation of the `Tools` component.
 */


const Tools = ({dnsLookUpPathName,domainDnsPathName,reverseIpPathName,ipLocationPathName,pingIpPathName,macAddressPathName,portCheckerPathName,websiteOperationgPathName,pageRankPathName}) => {
 
    return (
        <Card>
        <CardContent>
          <div className="box_container my-4 tools_wrapper" id="mTools">
            <h2 className="h5 fw_600">More Tools</h2>
            <div className="p-0 col-lg-12 my-3 px-lg-4 px-0 hide" id="mTools_links">
              
              {/* DNS Lookup */}
              <button className={dnsLookUpPathName ? 'slogan-active': 'dns_btns'}  >
                <Link className="related-links-href" title="Domain DNS Validation" to="/dashboard/dns-lookup">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M8.24 12.277c1.696 0 2.662 1.014 2.662 2.793 0 1.87-.95 2.903-2.662 2.903H5.849v-5.696H8.24zM7.297 16.81h.682c.939 0 1.448-.584 1.448-1.712 0-1.061-.536-1.657-1.448-1.657H7.297V16.81zM12.783 17.973v-5.692h1.155l2.371 3.203h.075v-3.203h1.373v5.692H16.61l-2.378-3.235h-.075v3.235H12.783zM21.039 16.352c.035.414.449.679 1.048.679.541 0 .912-.26.912-.635 0-.316-.249-.485-.899-.615l-.75-.15c-1.041-.197-1.578-.765-1.578-1.661 0-1.112.892-1.842 2.264-1.842 1.333 0 2.256.722 2.28 1.775H22.98c-.032-.402-.415-.682-.924-.682-.508 0-.843.241-.843.619 0 .312.252.493.852.611l.733.142c1.125.217 1.63.726 1.63 1.629 0 1.187-.907 1.905-2.398 1.905-1.44 0-2.347-.679-2.371-1.775H21.039z"/><g><path d="M7.013 9c1.044-1.385 2.436-2.495 4.049-3.189C10.538 6.701 10.102 7.783 9.764 9h2.108C12.662 6.541 13.869 5 15 5s2.338 1.541 3.128 4h2.108c-.338-1.217-.774-2.299-1.298-3.189C20.552 6.505 21.944 7.615 22.988 9h2.389C23.298 5.419 19.43 3 15 3S6.702 5.419 4.624 9H7.013zM22.988 21c-1.044 1.385-2.436 2.495-4.049 3.189.524-.89.96-1.972 1.298-3.189h-2.108c-.789 2.459-1.997 4-3.128 4s-2.338-1.541-3.128-4H9.764c.338 1.217.774 2.299 1.298 3.189C9.448 23.495 8.057 22.385 7.013 21H4.624C6.702 24.581 10.57 27 15 27s8.298-2.419 10.376-6H22.988z"/></g></svg>
                 DNS Lookup
                </Link>
                </button>
  
              {/* Domain DNS Validation */}
              <button className={domainDnsPathName ? 'slogan-active': 'dns_btns'} >
                <Link className="related-links-href" title="Domain DNS Validation" to="/dashboard/dns-record-validation">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="30px" height="30px"><path d="M47.868,24.504l-2.366-4.141l0.296-4.835c0.021-0.347-0.14-0.681-0.425-0.88l-3.946-2.763l-1.774-4.438c-0.13-0.324-0.42-0.558-0.765-0.615l-4.735-0.789L30.6,2.787c-0.26-0.238-0.625-0.322-0.961-0.221L25,3.947l-4.639-1.381C20.025,2.465,19.66,2.549,19.4,2.787l-3.553,3.257l-4.735,0.789c-0.345,0.057-0.635,0.291-0.765,0.615l-1.774,4.438l-3.946,2.763c-0.285,0.2-0.446,0.533-0.425,0.88l0.296,4.835l-2.366,4.141c-0.176,0.307-0.176,0.685,0,0.992l2.366,4.141l-0.296,4.835c-0.021,0.347,0.14,0.681,0.425,0.88l3.946,2.763l1.774,4.438c0.13,0.324,0.42,0.558,0.765,0.615l4.735,0.789l3.553,3.257c0.26,0.238,0.625,0.321,0.961,0.221L25,46.053l4.639,1.381c0.094,0.028,0.189,0.042,0.285,0.042c0.247,0,0.488-0.091,0.676-0.263l3.553-3.257l4.735-0.789c0.345-0.057,0.635-0.291,0.765-0.615l1.774-4.438l3.946-2.763c0.285-0.2,0.446-0.533,0.425-0.88l-0.296-4.835l2.366-4.141C48.044,25.189,48.044,24.811,47.868,24.504z M23.503,34.53l-9.073-8.188l1.34-1.484l7.327,6.612L34.06,14.458l1.682,1.083L23.503,34.53z"/></svg>
                  Domain DNS Validation
                </Link>
                </button>
             
              {/* Reverse IP Lookup */}
            <button  className={reverseIpPathName ? 'slogan-active': 'dns_btns'}>
                <Link className="related-links-href" title="Reverse IP Lookup" to="/dashboard/reverse-dns">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" width="24px" height="24px"><path fillRule="evenodd" d="M 20 12 C 20 16.417969 16.417969 20 12 20 C 7.582031 20 4 16.417969 4 12 C 4 7.582031 7.582031 4 12 4 C 13.113281 4 14.167969 4.238281 15.132813 4.648438 L 12.628906 7.324219 L 20.121094 7.074219 L 19.484375 0 L 17.273438 2.359375 C 15.710938 1.5 13.914063 1 12 1 C 5.925781 1 1 5.925781 1 12 C 1 18.074219 5.925781 23 12 23 C 18.074219 23 23 18.074219 23 12 Z"/></svg>Reverse IP Lookup
                </Link>
                </button>
  
              {/* IP Location Lookup */}
              <button  className={ipLocationPathName ? 'slogan-active': 'dns_btns'}>
                <Link className="related-links-href" title="Reverse IP Lookup" to="/dashboard/ip-location">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" width="24px" height="24px"><path d="M12,2C8.134,2,5,5.134,5,9c0,3.966,4.404,9.82,6.226,12.071c0.4,0.494,1.148,0.494,1.548,0C14.596,18.82,19,12.966,19,9C19,5.134,15.866,2,12,2z M12,11.5c-1.381,0-2.5-1.119-2.5-2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C14.5,10.381,13.381,11.5,12,11.5z"/></svg>
                  IP Location Lookup
                </Link>
                </button>
  
              {/* Ping IPv4 Address */}
              <button  className={pingIpPathName ? 'slogan-active': 'dns_btns'}>
                <Link className="related-links-href" title="Reverse IP Lookup" to="/dashboard/ping-ipv4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" width="24px" height="24px"><path d="M12,2C8.134,2,5,5.134,5,9c0,3.966,4.404,9.82,6.226,12.071c0.4,0.494,1.148,0.494,1.548,0C14.596,18.82,19,12.966,19,9C19,5.134,15.866,2,12,2z M12,11.5c-1.381,0-2.5-1.119-2.5-2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C14.5,10.381,13.381,11.5,12,11.5z"/></svg>Ping IPv4 Address
                </Link>
                </button>
  
              {/* MAC Address Lookup */}
              <button  className={macAddressPathName ? 'slogan-active': 'dns_btns'}>
                <Link className="related-links-href" title="SPF Record Checker" to="/dashboard/mac-lookup">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="30px" height="30px"><path fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="2" d="M47 50v-4c0-6.109-4.891-11-11-11H14C7.881 35 3 30.12 3 24l0 0c0-6.119 4.881-11 11-11h10M32 4L41 9"/><path d="M28,18c-1,0-1-0.655-1-1v-2h-2c-0.337,0-1,0-1-1v-2c0-1,0.663-1,1-1h2V9c0-0.345,0-1,1-1h18.389C46.726,8,47,8.28,47,8.625v8.75C47,17.72,46.726,18,46.389,18H28z"/></svg>MAC Address Lookup
                </Link>
                </button>
  
              {/* Port Checker */}
              <button  className={portCheckerPathName ? 'slogan-active': 'dns_btns'}>
                <Link className="related-links-href" title="SPF Record Checker" to="/dashboard/port-scanner">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="30px" height="30px"><path fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="2" d="M45,41c0,2.209-1.791,4-4,4H9c-2.209,0-4-1.791-4-4V9c0-2.209,1.791-4,4-4h32c2.209,0,4,1.791,4,4V41z"/><path d="M35 13L35 16 33 16 33 13 32 13 32 16 30 16 30 13 29 13 29 16 27 16 27 13 26 13 26 16 24 16 24 13 23 13 23 16 21 16 21 13 20 13 20 16 18 16 18 13 17 13 17 16 15 16 15 13 11 13 11 31 12 31 15 31 15 34 16 34 19 34 19 37 20 37 30 37 31 37 31 34 34 34 35 34 35 31 38 31 39 31 39 13z"/></svg>Port Checker
                </Link>
                </button>

              {/* Check Website Operating System */}
               <button  className={websiteOperationgPathName ? 'slogan-active': 'dns_btns'}>
                <Link className="related-links-href" title="Domain DNS Validation" to="/dashboard/website-server-software">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 26 26" width="40px" height="40px"><path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M3 21L3 12 13 16 23 12 23 21 13 25z"/><path d="M13 16L23 12 23 21 13 25z"/><path d="M23.307 11.071L23 12.136 20.61 12.51 21.098 9.956zM2.693 11.071L3 12.136 5.39 12.51 4.902 9.956z"/><path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M5.963,12.828C5.36,11.947,5,10.18,5,9c0-4.418,3.582-8,8-8s8,3.582,8,8c0,1.215-0.26,2.716-0.861,3.781"/><path d="M13,5c-2.207,0-4,1.791-4,4c0,2.208,1.793,4,4,4s4-1.792,4-4C17,6.791,15.207,5,13,5z M13,10.3 c-0.717,0-1.3-0.582-1.3-1.3s0.583-1.3,1.3-1.3s1.3,0.582,1.3,1.3S13.717,10.3,13,10.3z"/></svg>Check Website Operating System
                </Link>
                </button>
  
              {/* Check Google Page Rank */}
              <button  className={pageRankPathName ? 'slogan-active': 'dns_btns'}>
                <Link className="related-links-href" title="Reverse IP Lookup" to="/dashboard/pagerank">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" width="24px" height="24px"><path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"/></svg>Check Google Page Rank
                </Link>
                </button>
            </div>
          </div>
        </CardContent>
      </Card>
    )
}

export default Tools