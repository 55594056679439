import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    Grid,
    Stack,
    TextField,
    CircularProgress,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';
import { convertUrlToDomainFormat } from '../../utils/urlToDomain';

/**
 * The `DomainDNSValidation` component provides a user interface for validating Domain Name
 * System (DNS) records for a given domain. Users can enter a domain name, and the component
 * checks various DNS records associated with that domain. It displays the results in two
 * sections: DNS Tests and DNS Records.
 *
 * The component integrates with Material-UI for styling and uses tables to present the DNS
 * test results and DNS records. It also allows users to navigate back to the dashboard.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `DomainDNSValidation` component.
 */
const DomainDNSValidation = () => {
    /**
     * Design a schema for creating `DomainDNSValidation` with form validations.
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     */
    const schema = yup.object().shape({
        domain: yup
            .string()
            .required('Domain is Required')
            .url('Invalid URL Format. Please Enter a Valid URL in The Following Format: https://example.com.')
            .test('No-Trailing-Slash', 'URL Should Not End With a Slash ("/")', (value) => {
                if (value && value.endsWith('/')) {
                    return false;
                }
                return true;
            }),
    });

    // Initialization and state management
    const url = useLocation();
    const domainPath = url.pathname;
    const authToken = JSON.parse(localStorage.getItem('token'));
    const [loader, setLoader] = useState(false);
    const [dnsTest, setDnsTest] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles DomainDNSValidation  upon form submission
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for DomainDNSValidation .
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     *
     */
    const handleSubmitForm = async (data) => {
        setLoader(true);
        const { domain } = data;
        const formattedDomain = convertUrlToDomainFormat(domain);
        try {
            const response = await api.get(`${ENV.appBaseUrl}/dns/dns-validation?domain=${formattedDomain}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response?.data?.success) {
                setDnsTest(response?.data?.results);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    return (
        <>
            <div className="padding_px">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom className="ml-10">
                            <Link to="/dashboard">
                                <Button className="back_btn" variant="contained">
                                    <Iconify icon="bi:arrow-left" />
                                </Button>
                            </Link>
                            Domain DNS Validation
                            <div className="mt-10">
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to="/dashboard" className="domain_bread">
                                        Dashboard
                                    </Link>
                                    <Link to="/dashboard/dns-record-validation" className="domain_bread">
                                        Domain DNS Validation
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </Typography>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <Card sx={{ p: 2 }}>
                                <CardContent>
                                    <TextField
                                        {...register('domain')}
                                        id="outlined-basic"
                                        label="Domain"
                                        placeholder="Example: dnschecker.org"
                                        variant="outlined"
                                        sx={{ marginRight: '8px', width: '100%' }}
                                        type="text"
                                        error={!!errors?.domain}
                                        helperText={errors.domain?.message}
                                    />
                                </CardContent>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '16px',
                                        marginRight: '22px',
                                    }}
                                >
                                    <Stack spacing={2} direction="row">
                                        <Button variant="contained" type="submit" color="primary">
                                            Validate DNS
                                        </Button>
                                    </Stack>
                                </div>
                            </Card>
                            <br />
                        </form>
                    </Grid>
                </Grid>
                <Tools domainDnsPathName={domainPath} />
            </div>

            {loader ? (
                <div
                    className="spinner"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '160px' }}
                >
                    <CircularProgress value={100} />
                </div>
            ) : dnsTest && dnsTest.length > 0 ? (
                <Card sx={{ p: 2 }}>
                    <CardContent>
                        <div className="my-4" id="drv_results">
                            <div className="box_container table-reponsive">
                                <table className="table mb-0 w-full">
                                    <thead>
                                        <tr>
                                            <th className="result_heading_style h3 border-0 text-start" colSpan="4">
                                                DNS Tests
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="border border-0">&nbsp;</th>
                                            <th className="border border-0 text-start f-size-20">Category</th>
                                            <th className="border border-0 text-start f-size-20">Host</th>
                                            <th className="border border-0 text-start f-size-20 ">Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dnsTest?.map((test, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="border border-0 text-center">
                                                        <span className="text-warning">
                                                            <img
                                                                src="/assets/icons/exclamation-triangle-colored.svg"
                                                                alt="exclamation"
                                                            />
                                                        </span>
                                                    </td>
                                                    <td className="border border-0">{test?.Category}</td>
                                                    <td className="border border-0">{test?.Host}</td>
                                                    <td className="border border-0">{test?.Result}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="box_container table-reponsive mt-4">
                                <table className="table mb-0 w-full">
                                    <thead>
                                        <tr>
                                            <th className="result_heading_style h3 border-0 text-start" colSpan="8">
                                                DNS
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="border border-0 text-start">NS</th>
                                            <th className="border border-0 text-start">IP Address</th>
                                            <th className="border border-0 text-start">TTL</th>
                                            <th className="border border-0 text-start">Time (ms)</th>
                                            <th className="border border-0 text-start ">Status</th>
                                            <th className="border border-0 text-start ">Auth</th>
                                            <th className="border border-0 text-start ">Parent</th>
                                            <th className="border border-0 text-start ">Local</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dnsTest && dnsTest.some((test) => test?.NS) ? (
                                            dnsTest.map((test, index) => {
                                                if (test?.NS) {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="border border-0">{test.NS.name}</td>
                                                            <td className="border border-0">{test.NS.ip}</td>
                                                            <td className="border border-0">{test.NS.ttl || 'N/A'}</td>
                                                            <td className="border border-0">{test.NS.time || 'N/A'}</td>
                                                            {test.NS.status && (
                                                                <td className="border border-0 text-center">
                                                                    <span className="text-success">
                                                                        <img
                                                                            src="/assets/icons/checked_filled.svg"
                                                                            alt="checked"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            )}
                                                            {test.NS.auth && (
                                                                <td className="border border-0 text-center">
                                                                    <span className="text-success">
                                                                        <img
                                                                            src="/assets/icons/checked_filled.svg"
                                                                            alt="checked"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            )}
                                                            {test.NS.parent && (
                                                                <td className="border border-0 text-center">
                                                                    <span className="text-success">
                                                                        <img
                                                                            src="/assets/icons/checked_filled.svg"
                                                                            alt="checked"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            )}
                                                            {test.NS.local && (
                                                                <td className="border border-0 text-center">
                                                                    <span className="text-success">
                                                                        <img
                                                                            src="/assets/icons/checked_filled.svg"
                                                                            alt="checked"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="border border-0">
                                                    No Record Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ) : null}
        </>
    );
};

export default DomainDNSValidation;
