import { configureStore } from '@reduxjs/toolkit'
import dataSlice from './features/slices/UserProfileSlice';

/**
 * Configures and creates the Redux store for the application.
 *
 * This setup uses Redux Toolkit's `configureStore` function to create the store with
 * a single reducer. The reducer manages the state of user profile data through the
 * `UserProfileData` slice, which is imported from `UserProfileSlice`.
 *
 * The store is then exported for integration with the React application, typically
 * used with the `<Provider>` component from `react-redux` to make the store available
 * to all components.
 *
 * @type {Object}
 * @property {Function} store - The Redux store instance.
 *
 * Author: Muhammad Rooman
 * Date: 1 August, 2024
 */
export const store = configureStore({
  reducer: {
    UserProfileData : dataSlice,
  },
})