import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';
import { urlToDomainFormat } from '../../utils/urlToDomain';

/**
 * The `IpPinger` component allows users to ping an IPv4 address and view the ping results.
 * Users can enter an IPv4 address, and the component will display the ping results, including
 * response times, TTL (Time to Live), packet summary, and latency summary.
 *
 * The component provides an intuitive user interface with Material-UI styling, and it allows
 * users to navigate back to the dashboard.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `IpPinger` component.
 */
const IpPinger = () => {

    /**
     * Design a schema for creating `IpPinger` with form validations.
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     */
    const schema = yup.object().shape({
        host: yup
            .string()
            .required('Domain or IP is Required')
            .url('Invalid URL Format. Please Enter a Valid URL in The Following Format: https://example.com.')
            .test('No-Trailing-Slash', 'URL Should Not End With a Slash ("/")', (value) => {
                if (value && value.endsWith('/')) {
                    return false;
                }
                return true;
            }),
    });

    // Initialization and state management
    const url = useLocation();
    const pingIpPath = url.pathname;
    const authToken = JSON.parse(localStorage.getItem('token'));
    const [loader, setLoader] = useState(false);
    const [ipPing, setIpPing] = useState({});

    // They are part of the schema, form management and validation logic for IpPinger
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Creates IpPinger data upon form submission
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for IpPinger.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     *
     */
    const handleSubmitForm = async (data) => {
        const { host } = data;
        const formattedDomain = urlToDomainFormat(host);
        setLoader(true);
        try {
            const response = await api.get(`${ENV.appBaseUrl}/dns/ping-ipv4?host=${formattedDomain}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response?.data?.success) {
                setIpPing(response?.data?.result);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    return (
        <>
            <div className="padding_px">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom className="ml-10">
                            <Link to="/dashboard">
                                <Button className="back_btn" variant="contained">
                                    <Iconify icon="bi:arrow-left" />
                                </Button>
                            </Link>
                            IP Pinger - Ping IPv4
                            <div className="mt-10">
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to="/dashboard" className="domain_bread">
                                        Dashboard
                                    </Link>
                                    <Link to="/dashboard/ping-ipv4" className="domain_bread">
                                        IP Pinger - Ping IPv4
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </Typography>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <Card sx={{ p: 2 }}>
                                <CardContent>
                                    <TextField
                                        {...register('host')}
                                        id="outlined-basic"
                                        label="Domain/IP"
                                        placeholder="Enter Domain or IP"
                                        variant="outlined"
                                        sx={{ marginRight: '8px', width: '100%' }}
                                        type="text"
                                        error={!!errors?.host}
                                        helperText={errors.host?.message}
                                    />
                                </CardContent>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '16px',
                                        marginRight: '22px',
                                    }}
                                >
                                    <Stack spacing={2} direction="row">
                                        <Button variant="contained" type="submit" color="primary">
                                            Validate DNS
                                        </Button>
                                    </Stack>
                                </div>
                            </Card>
                            <br />
                        </form>
                    </Grid>
                </Grid>
                <Tools pingIpPathName={pingIpPath} />
                {loader ? (
                    <div
                        className="spinner"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '160px',
                        }}
                    >
                        <CircularProgress value={100} />
                    </div>
                ) : ipPing?.pingResults?.length || ipPing?.ls || ipPing?.statistics ? (
                    <div className="box_container  table-reponsive  overflow-auto my-4" id="ip4p_results" style={{ minHeight: '500px' }}>
                        <table className="table mb-0 w-full">
                            <thead>
                                <tr className="mt-3">
                                    <th
                                        className="result_heading_style border border-0"
                                        style={{ padding: '0px !important' }}
                                        colSpan="4"
                                    >
                                        <h5 className="result_heading_style h3 mb-0 mt-0 text-start">
                                            Ping Result for IP: <span className="text_green">{ipPing?.ls?.ip}</span>
                                        </h5>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="border-0 text-start f-size-20">Connected To</th>
                                    <th className="border-0 text-start f-size-20">Response Time</th>
                                    <th className="border-0 text-start f-size-20">TTL</th>
                                    <th className="border-0 text-start f-size-20">No of Bytes</th>
                                </tr>
                                {ipPing?.pingResults?.length
                                    ? ipPing?.pingResults?.map((result, index) => {
                                          return (
                                              <tr key={index}>
                                                  <td className="border-0">{result?.host}</td>
                                                  <td className="border-0">{result?.time} ms</td>
                                                  <td className="border-0">{result?.ttl}</td>
                                                  <td className="border-0">{result?.bytes}</td>
                                              </tr>
                                          );
                                      })
                                    : 'No Record Found'}

                                <tr>
                                    <th className="border border-0 h5 text-start f-size-36 bg-lg-green" colSpan="4">
                                        Packet Summary
                                    </th>
                                </tr>
                                <tr>
                                    <th className="border-0 text-start f-size-20">Sent</th>
                                    <th className="border-0 text-start f-size-20">Received</th>
                                    <th className="border-0 text-start f-size-20">Loss</th>
                                    <th className="border-0 text-start f-size-20">Time</th>
                                </tr>
                                <tr>
                                    <td className="border-0">{ipPing?.statistics?.packetsTransmitted}</td>
                                    <td className="border-0">{ipPing?.statistics?.packetsReceived}</td>
                                    <td className="border-0">{ipPing?.statistics?.packetLoss}</td>
                                    <td className="border-0">{ipPing?.statistics?.time}</td>
                                </tr>
                                <tr>
                                    <th className="border-0 h5 text-start f-size-36 bg-lg-green" colSpan="4">
                                        Latency Summary
                                    </th>
                                </tr>
                                <tr>
                                    <th className="border-0 text-start f-size-20">Min</th>
                                    <th className="border-0 text-start f-size-20">Max</th>
                                    <th className="border-0 text-start f-size-20">Avg</th>
                                    <th className="border-0 text-start f-size-20">StdDev</th>
                                </tr>
                                <tr>
                                    <td className="border-0">{ipPing?.ls?.min}</td>
                                    <td className="border-0">{ipPing?.ls?.max}</td>
                                    <td className="border-0">{ipPing?.ls?.avg}</td>
                                    <td className="border-0">{ipPing?.ls?.stddev}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default IpPinger;
