import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';
import Iconify from '../../components/iconify';
import { formatDate } from '../../utils/formatTime';

/**
 * Create Report Component.
 *
 * All rights Reseverd | Arhamsoft Pvt @2023
 *
 * @returns {JSX.Element} - JSX representation of the component.
 */
function Report() {
    /**
     * Design create report form schema with validations
     *
     * Author: Ali Haider
     * Date: 29 Nov, 2023
     *
     */
    const schema = yup.object().shape({
        domain: yup
            .string()
            .required('Domain is Required')
            .test('no-trailing-slash', 'Domain should not end with a slash ("/")', (value) => {
                if (value && value.endsWith('/')) {
                    return false;
                }
                return true;
            }),
    });

    // Initialization and state management
    const [loader, setLoader] = useState(false);
    const [domainData, setDomainData] = useState();
    const [websiteName, setWebsiteName] = useState();
    const [dnsRecord, setDnsRecord] = useState();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Function for getting a domain upon form submission and mapping tags.
     *
     * @param {object} data - The website data from the form.
     * @return {void}
     *
     * Author: Ali Haider
     * Date: 29 Nov, 2023
     *
     */
    const handleFormSubmit = async (data) => {
        setLoader(true);
        // Ensure the domain starts with "https://"
        const updatedDomain = data.domain.startsWith('https://') ? data.domain : `https://${data.domain}`;
        fetchDnsRecords(updatedDomain);
        try {
            const response = await api.post(`${ENV.appBaseUrl}/report`, { domain: updatedDomain });

            if (response?.data?.success) {
                setLoader(false);
                setDomainData(response?.data)

                toast.success(response?.data?.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message);
            setLoader(false);
        }
    };

    const domainExpiryDate = new Date(domainData?.domainData?.domainExpiryDate);

    const currentDate = new Date();
    const daysRemaining = Math.ceil((domainExpiryDate - currentDate) / (1000 * 60 * 60 * 24));


    const fetchDnsRecords = async (updatedDomain) => {
        setLoader(true);
        setWebsiteName(updatedDomain)
        try {
            const response = await api.post(`${ENV.appBaseUrl}/report/dns`, { domain: updatedDomain });
            if (response?.data) {
                setLoader(false);
                setDnsRecord(response?.data)
            }
        } catch (error) {
            toast.error(error.response?.data?.message);
            setLoader(false);
        }
    }

    /**
   * Function to download the content of the apiResponse div as a PDF.
   *
   * @return {void}
   */
    const handleDownloadPDF = () => {
        const pdfElement = document.getElementById('apiResponse');

        if (pdfElement) {
            const pdfOptions = {
                margin:3,
                filename: 'report.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                // pagebreak: { mode: 'avoid-all', avoid: '.avoid-page-break' },
            };

            html2pdf(pdfElement, pdfOptions);
        }
    };

    /**
     * Render DNS records based on the record type.
     *
     * @param {string} type - The type of DNS record.
     * @param {Array} records - The array of DNS records.
     * @return {JSX.Element | null} - The rendered JSX or null if records are empty.
     *
     * Author: Ali Haider
     * Date: 04 Dec, 2023
     */
    function renderRecordType(type, records) {
        return (

            <>

                {type === 'A' && records?.length > 0 && (<div>
                    <thead>

                        <h3>{type}</h3>
                        <tr>
                            <th><span className="snoop-table-alt-heading">Type</span></th>
                            <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                            <th><span className="snoop-table-alt-heading">TTL</span></th>
                            <th><span className="snoop-table-alt-heading">Address</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {records?.map((record, index) => (
                            <tr key={index}>
                                <td>{type}</td>
                                <td>{websiteName || "N/A"}</td>
                                <td>{record?.ttl || "N/A"}</td>
                                <td> {record || "N/A"}</td>
                            </tr>

                        ))}
                    </tbody>
                </div>
                )}

                {type === 'AAAA' && records?.length > 0 && (
                    <div>
                        <thead>

                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Address</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td> {record || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}
                {type === 'CNAME' && records?.length > 0 && (
                    <div>
                        <thead>
                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Address</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td> {record || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}
                {type === 'MX' && records?.length > 0 && (
                    <div>
                        <thead>

                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Prefernces</span></th>
                                <th><span className="snoop-table-alt-heading">Address</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td>{record?.priority || "N/A"}</td>
                                    <td> {record?.exchange || "N/A"}</td>
                                </tr>

                            ))
                            }
                        </tbody>
                    </div>
                )}
                {type === 'NS' && records?.length > 0 && (
                    <div>
                        <thead>
                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Canonical Name</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td>{record || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}

                {type === 'SOA' && records?.length > 0 && (
                    <div>
                        <thead>
                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Primary NS</span></th>
                                <th><span className="snoop-table-alt-heading">Responsible Email</span></th>

                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.minttl || "N/A"}</td>
                                    <td>{record?.nsname || "N/A"}</td>
                                    <td>{record?.hostmaster || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}
                {type === 'TXT' && records?.length > 0 && (
                    <div>
                        <thead>
                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Record</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td>{record[0] || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}
                {type === 'CAA' && records?.length > 0 && (
                    <div>
                        <thead>
                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Record</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td>{record || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}
                {type === 'DS' && records?.length > 0 && (
                    <div>
                        <thead>
                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Key Tag</span></th>
                                <th><span className="snoop-table-alt-heading">Algorithm</span></th>
                                <th><span className="snoop-table-alt-heading">Digest Type</span></th>
                                <th><span className="snoop-table-alt-heading">Digest</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td>{record?.key_tag || "N/A"}</td>
                                    <td>{record?.algorithm || "N/A"}</td>
                                    <td>{record?.digest_type || "N/A"}</td>
                                    <td>{record?.digest || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}
                {type === 'DNSKEY' && records?.length > 0 && (
                    <div>
                        <thead>
                            <h3>{type}</h3>
                            <tr>
                                <th><span className="snoop-table-alt-heading">Type</span></th>
                                <th><span className="snoop-table-alt-heading">Domain Name</span></th>
                                <th><span className="snoop-table-alt-heading">TTL</span></th>
                                <th><span className="snoop-table-alt-heading">Algorithm</span></th>
                                <th><span className="snoop-table-alt-heading">Protocol</span></th>
                                <th><span className="snoop-table-alt-heading">Flag</span></th>
                                <th><span className="snoop-table-alt-heading">Key ID</span></th>
                                <th><span className="snoop-table-alt-heading">Key</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records?.map((record, index) => (
                                <tr key={index}>
                                    <td>{type}</td>
                                    <td>{websiteName || "N/A"}</td>
                                    <td>{record?.ttl || "N/A"}</td>
                                    <td>{record?.algorithm || "N/A"}</td>
                                    <td>{record?.protocol || "N/A"}</td>
                                    <td>{record?.flag || "N/A"}</td>
                                    <td>{record?.key_id || "N/A"}</td>
                                    <td>{record?.key || "N/A"}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </div>
                )}
            </>
        );
    }

    /**
     * Renders a Material-UI CircularProgress spinner when the loader is active.
     *
     * @returns {JSX.Element} CircularProgress spinner if loader is active, otherwise null.
     *
     * Author: Ali Haider
     * Date: 29 Nov, 2023
     */
    if (loader) {
        return (
            <div className="spinner-wrapper">
                <CircularProgress value={100} />
            </div>
        );
    }

    return (
        <div className="padding_px">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" gutterBottom className="ml-10">
                        <Link to="/dashboard/app">
                            <Button className="back_btn" variant="contained">
                                <Iconify icon="bi:arrow-left" />
                            </Button>
                        </Link>
                        Report
                        <div className="mt-10">
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/dashboard/app" className="domain_bread">
                                    Dashboard
                                </Link>
                                <Typography color="text.primary">Report</Typography>

                            </Breadcrumbs>
                        </div>
                    </Typography>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Card sx={{ p: 2 }}>
                            <CardContent>
                                <TextField
                                    id="outlined-basic"
                                    label="Domain"
                                    variant="outlined"
                                    sx={{ marginRight: '8px', width: '100%' }}
                                    {...register('domain')}
                                    type="text"
                                    error={!!errors.domain}
                                    helperText={errors.domain?.message}
                                />
                            </CardContent>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '16px',
                                    marginRight: '22px',
                                }}
                            >
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" type="submit" color="primary">
                                        Submit
                                    </Button>
                                </Stack>
                            </div>
                        </Card>
                        <br />
                    </form>
                </Grid>
                {dnsRecord || domainData ? <div id="apiResponse" className='w-full'>
                    <div className='site_link_logo'>
                        <img src="/assets/icons/site-logo.png" alt="Logo" />
                        <a href="/">https://webhealthchecker.arhamsoft.org</a>
                    </div>
                    {domainData && (
                        <>
                        <div className='page-break-after'>
                        <div className="table-reponsive ">
                                <table className="table snoop-table-alt w-full">
                                    <tbody>
                                        <tr>
                                            <th className="report_heading_style h3 border-0 text-start" colSpan="4">Domain</th>
                                        </tr>
                                        {domainData?.domainData ? (
                                            <>
                                                <tr>
                                                    <td className="text-primary">Domain:</td>
                                                    <td>
                                                        {domainData?.domainData?.domain}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">Domain Status: </td>
                                                    <td>
                                                        {domainData?.domainData?.domainExpired === true ? (
                                                            <span style={{ color: 'red' }}>Expired</span>
                                                        ) : (
                                                            <span style={{ color: 'green' }}>Active</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">Domain Expery Days:</td>
                                                    <td>
                                                        {daysRemaining || 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">Domain Heath:</td>
                                                    <td>
                                                        {domainData?.domainData?.health}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">Mail Gun:</td>
                                                    <td>
                                                        {domainData?.domainData?.isUsingMailGun === true ? "Active" : "Mail gun not implemented"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">Domain Load Time:</td>
                                                    <td>{domainData?.domainData?.loadingTime}</td>
                                                </tr></>) : (
                                            <tr>
                                                <td colSpan="2">No Record Found</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                            <div className="table-reponsive ">

                                <table className="table snoop-table-alt w-full">
                                    <tbody>
                                        <tr>
                                            <th className="report_heading_style h3 border-0 text-start" colSpan="4">SSL Data</th>
                                        </tr>
                                        {domainData?.sslData ? (
                                            <>
                                                <tr>
                                                    <td className="text-primary">Domain:</td>
                                                    <td>
                                                        {domainData?.domainData?.domain}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">SSL Status: </td>
                                                    <td>
                                                        {domainData?.sslData?.sslExpired === true ? (
                                                            <span style={{ color: 'red' }}>Expired</span>
                                                        ) : (
                                                            <span style={{ color: 'green' }}>Active</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">SSL Expery Days:</td>
                                                    <td>
                                                        {domainData?.sslData?.sslExpiryDays || 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">SSL Expiry Date:</td>
                                                    <td>
                                                        {formatDate(domainData?.sslData?.sslExpiryDate) || 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">SSL Issuer:</td>
                                                    <td>{domainData?.sslData?.sslIssuer}</td>
                                                </tr>
                                            </>) : (
                                            <tr>
                                                <td colSpan="2">No Record Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-reponsive ">

                                <table className="table snoop-table-alt w-full">
                                    <tbody>
                                        <tr>
                                            <th className="report_heading_style h3 border-0 text-start" colSpan="4">UpTime Monitor</th>
                                        </tr>
                                        {domainData?.monitor ? (
                                            <>
                                                <tr>
                                                    <td className="text-primary">Domain:</td>
                                                    <td>
                                                        {domainData?.monitor?.domain}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">Monitor SSL Error: </td>
                                                    <td>
                                                        {domainData?.monitor?.errorsLog}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-primary">Up-Down Time:</td>
                                                    <td>
                                                        {domainData?.monitorResponse ? `${domainData?.monitorResponse} %` : null}
                                                    </td>
                                                </tr>
                                            </>) : (
                                            <tr>
                                                <td colSpan="2">No Record Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        </>
                    )}
                    {dnsRecord && (<> {dnsRecord?.getAllDnsRecord &&
                    <div className="page-break-before">
                    <div className="table-reponsive ">
                        <div>
                            <div className="report_heading_style h3 border-0 text-start" colSpan="4">DNS Lookup</div>
                        </div>
                        <table className="table snoop-table-alt w-full">
                            {dnsRecord?.getAllDnsRecord &&
                                Object.entries(dnsRecord?.getAllDnsRecord)?.map(([type, records], index) => {
                                    if (!records || (Array.isArray(records) && records.length === 0)) {
                                        return null;
                                    }
                                    return <>
                                        {renderRecordType(type, records)}
                                    </>
                                })}
                        </table>
                    </div>
                    </div>}
                    <div className='page-break-after'>
                    <div className="table-reponsive ">
                            <table className="table mb-0 w-full">
                                <thead>
                                    <tr>
                                        <th className="report_heading_style h3 border-0 text-start" colSpan="4">DNS Tests</th>
                                    </tr>
                                    <tr>
                                        <th className="border border-0 text-start f-size-20">Category</th>
                                        <th className="border border-0 text-start f-size-20">Host</th>
                                        <th className="border border-0 text-start f-size-20 ">Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dnsRecord?.validateDns?.length ? (
                                        dnsRecord?.validateDns?.map((dnsTest, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td className="border border-0">{dnsTest?.Category}</td>
                                                    <td className="border border-0">{dnsTest?.Host}</td>
                                                    <td className="border border-0">{dnsTest?.Result}</td>
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No Record Found</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                        </div>


                        <div className="table-reponsive  overflow-auto " >
                            <table className="table mb-0 w-full">
                                <thead>
                                    <tr>
                                        <th className="report_heading_style h3 border-0 text-start" colSpan="4">Ping IPv4 Address</th>
                                    </tr>
                                    <tr className="mt-3">
                                        <th className="text-start border border-0 f-size-20" colSpan="4">
                                            Ping Result for IP:
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <th className="border-0 text-start f-size-16">Connected To</th>
                                        <th className="border-0 text-start f-size-16">Response Time</th>
                                        <th className="border-0 text-start f-size-16">TTL</th>
                                        <th className="border-0 text-start f-size-16">No of Bytes</th>
                                    </tr>
                                    {dnsRecord?.pingResult?.pingResults?.length ? (
                                        dnsRecord?.pingResult?.pingResults?.map((ping, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="border-0">{ping?.host}</td>
                                                    <td className="border-0">{ping?.time} ms</td>
                                                    <td className="border-0">{ping?.ttl}</td>
                                                    <td className="border-0">{ping?.bytes}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No Record Found</td>
                                        </tr>
                                    )}
                                    {dnsRecord?.pingResult?.statistics ? (<><tr>
                                        <th className="text-start border border-0 f-size-20 " colSpan="4">Packet Summary</th>
                                    </tr>
                                        <tr>
                                            <th className="border-0 text-start f-size-16">Sent</th>
                                            <th className="border-0 text-start f-size-16">Received</th>
                                            <th className="border-0 text-start f-size-16">Loss</th>
                                            <th className="border-0 text-start f-size-16">Time</th>
                                        </tr>

                                        <tr>
                                            <td className="border-0">{dnsRecord?.pingResult?.statistics?.packetsTransmitted}</td>
                                            <td className="border-0">{dnsRecord?.pingResult?.statistics?.packetsReceived}</td>
                                            <td className="border-0">{dnsRecord?.pingResult?.statistics?.packetLoss}</td>
                                            <td className="border-0">{dnsRecord?.pingResult?.statistics?.time}</td>
                                        </tr></>) : null}
                                    {dnsRecord?.pingResult?.ls ? (<><tr>
                                        <th className="text-start border border-0 f-size-20 " colSpan="4">Latency Summary</th>
                                    </tr>
                                        <tr>
                                            <th className="border-0 text-start f-size-20">Min</th>
                                            <th className="border-0 text-start f-size-20">Max</th>
                                            <th className="border-0 text-start f-size-20">Avg</th>
                                            <th className="border-0 text-start f-size-20">StdDev</th>
                                        </tr>
                                        <tr>
                                            <td className="border-0">{dnsRecord?.pingResult?.ls?.min}</td>
                                            <td className="border-0">{dnsRecord?.pingResult?.ls?.max}</td>
                                            <td className="border-0">{dnsRecord?.pingResult?.ls?.avg}</td>
                                            <td className="border-0">{dnsRecord?.pingResult?.ls?.stddev}</td>
                                        </tr></>) : null}
                                </tbody>
                            </table>
                        </div>

                        {dnsRecord?.results?.length ? (<div className="report_heading_style h3 border-0 text-start " colSpan="4">IP Location Lookup</div>) : null}
                        <div className='row'>
                            {dnsRecord?.results?.map((item, index) => {
                                const locArray = item?.data?.loc?.split(',') || ['', ''];
                                const [latitude, longitude] = locArray;
                                return (
                                    <>
                                        {item?.service === "IPInfo.io" && (
                                            <div className='col-6' key={index}>
                                                <div className="table-reponsive  overflow-auto " >
                                                    <table className="table w-full snoop-table-alt">
                                                        <tbody>
                                                            <tr>
                                                                <th className="text-start f-size-20" colSpan="4">{item?.service}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">IP:</th>
                                                                <td className="text-center">{item?.data?.ip || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">Country:</th>
                                                                <td className="text-start ">{item?.data?.country || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start"> State:</th>
                                                                <td className="text-center">{item?.data?.region || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start"> City:</th>
                                                                <td className="text-center">{item?.data?.city || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">Latitude:</th>
                                                                <td className="text-center">{latitude || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">Longitude:</th>
                                                                <td className="text-center">{longitude || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start"> ISP:</th>
                                                                <td className="text-center">{item?.data?.org || "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                        {item?.service === "DB IP" && (
                                            <div className='col-6'>
                                                <div className="table-reponsive  overflow-auto " >
                                                    <table className="table w-full snoop-table-alt">
                                                        <tbody>
                                                            <tr>
                                                                <th className='f-size-20' colSpan="4"> {item?.service || "N/A"}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">IP:</th>
                                                                <td className="text-center">{item?.data?.ipAddress || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">Country:</th>
                                                                <td className="text-start ">{item?.data?.countryName || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start"> State:</th>
                                                                <td className="text-center">{item?.data?.stateProv || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start"> City:</th>
                                                                <td className="text-center"> {item?.data?.city || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">Latitude:</th>
                                                                <td className="text-center">{latitude || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start">Longitude:</th>
                                                                <td className="text-center">{longitude || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-start"> ISP:</th>
                                                                <td className="text-center">{item?.data?.organization || "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                        {dnsRecord?.pageRank?.length ? (<div className="my-4" style={{ minHeight: '145px', }} id="prc_results">
                            <h3 className="report_heading_style mb-3">{dnsRecord?.pageRank[0]?.domain}</h3>
                            <div className="page_wrank">
                                <h4 className="rank_heading f-size-20">Page Rank</h4>
                                <div className="progress_container">
                                    <div className="emoji_wrank">
                                        <span>
                                            <img src="/assets/icons/broken-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/sad-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/sad2-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/sad2-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/smile-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/smile2-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/happy-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/happy2-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/smart-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/heart-emoji.svg" alt="broken-emoji" />
                                        </span>
                                        <span>
                                            <img src="/assets/icons/heart2-emoji.svg" alt="broken-emoji" />
                                        </span>
                                    </div>
                                    <div className="progress">
                                        <div className="bar" style={{ width: `${dnsRecord?.pageRank[0]?.page_rank_integer * 11}%` }}> </div>
                                    </div>
                                </div>
                                <strong className="h2 rank_num">
                                    <span style={{ color: 'rgb(255, 183, 3)' }}>{dnsRecord?.pageRank[0]?.page_rank_integer}</span>{' '}
                                    <span style={{ color: 'rgb(175, 175, 175)' }}>/</span> 10 </strong>
                            </div>
                        </div>) : null}
                        {dnsRecord?.operatingSystemInfo ? (<div className="my-4" id="wss_results">
                            <h2 className="result_heading_style h3 f-size-24">Results for <span className="text_blue">{websiteName || "N/A"}</span>
                            </h2>
                            <div className="apache_bg">{dnsRecord?.operatingSystemInfo?.server}</div>
                        </div>) : null}
                    </>)}
                </div> : null}


            </Grid >
            {dnsRecord || domainData ? <button onClick={handleDownloadPDF} id="downloadBtn" className='download_pdf' >
                <img src="/assets/download_btn.png" alt="download_btn" />
            </button> : null}
        </div >
    );
}

export default Report;
