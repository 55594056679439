import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Breadcrumbs,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';

/**
 * The `ReverseIpLookup` component enables users to perform reverse IP lookup by converting an IP address to its associated hostname.
 * Users can input an IP address, and the component displays the corresponding hostname. It provides a clean and user-friendly interface
 * with Material-UI styling for easy navigation. Users can return to the dashboard from this component.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `ReverseIpLookup` component.
 */
const ReverseIpLookup = () => {
    /**
     * Design a schema for creating `DnsReverseIpLookupookup` with form validations.
     *
     * Author: Muhammad Rooman
     * Date: 9 Oct, 2023
     */
    const schema = yup.object().shape({
        ipAddress: yup
            .string()
            .required('IP Address is Required')
            .matches(
                /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                'Invalid IP Address Format. Please Enter a Valid IP Address.'
            ),
    });

    // Initialization and state management
    const [ipReverseAddress, setIpReverseAddress] = useState(null);
    const url = useLocation();
    const ReverseIpPath = url.pathname;
    const [loader, setLoader] = useState(false);
    const authToken = JSON.parse(localStorage.getItem('token'));

    // They are part of the schema, form management and validation logic for ReverseIpLookup
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles ReverseIpLookup upon form submission
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for ReverseIpLookup.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 9 Oct, 2023
     *
     */
    const handleSubmitForm = async (data) => {
        setLoader(true);
        const { ipAddress } = data;
        try {
            const response = await api.get(`${ENV.appBaseUrl}/dns/reverse-ip-lookup?ipAddress=${ipAddress}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
                },
            });
            if (response?.data?.success) {
                setIpReverseAddress(response.data.result);
                toast.success(response.data?.message);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    return (
        <div className="padding_px">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" gutterBottom className="ml-10">
                        <Link to="/dashboard">
                            <Button className="back_btn" variant="contained">
                                <Iconify icon="bi:arrow-left" />
                            </Button>
                        </Link>
                        Reverse IP Lookup
                        <div className="mt-10">
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/dashboard" className="domain_bread">
                                    Dashboard
                                </Link>
                                <Link to="/dashboard/reverse-dns" className="domain_bread">
                                    Reverse IP Lookup
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Card sx={{ p: 2 }}>
                            <CardContent>
                                <TextField
                                    {...register('ipAddress')}
                                    id="outlined-basic"
                                    label="Enter IP to convert IP to Hostname"
                                    placeholder="Example: 8.8.8.8"
                                    variant="outlined"
                                    sx={{ marginRight: '8px', width: '100%' }}
                                    type="text"
                                    error={!!errors?.ipAddress}
                                    helperText={errors.ipAddress?.message}
                                />
                            </CardContent>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '16px',
                                    marginRight: '22px',
                                }}
                            >
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" type="submit" color="primary">
                                        Submit
                                    </Button>
                                </Stack>
                            </div>
                        </Card>
                        <br />
                    </form>
                </Grid>
            </Grid>
            <Tools reverseIpPathName={ReverseIpPath} />
            {loader ? (
                <div
                    className="spinner"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '160px' }}
                >
                    <CircularProgress value={100} />
                </div>
            ) : ipReverseAddress && ipReverseAddress.length > 0 ? (
                <div className="mt-3 overflow-auto" id="rd_results">
                    <div className="result_container">
                        <p className="h3 fw_400 f-size-30">
                            IP: <span className="fw-bold f-size-30"> {ipReverseAddress[0].ip}</span>
                        </p>
                        <p className="h3 fw_400 mb-0 f-size-30">
                            Hostname:
                            <span className="result_hostname cursor-pointer fw-bold f-size-30" title="copy">
                                {ipReverseAddress[0].hostname}
                                <i className="h5 icon icon-copy-outline icon-sm ms-2 mb-0 valign-middle" />
                            </span>
                        </p>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default ReverseIpLookup;
