// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, color: '#fff' }} />
);

/**
 * Implement permissions for nav
 *
 * Author: Ali Haider
 * Date: 15 Sep, 2023
 */
const navConfig = [
    {
        title: 'Dashboard',
        path: '/dashboard/app',
        icon: icon('ic_analytics'),
        name: 'View-Dashboard',
    },
    {
        title: 'Web Monitoring',
        icon: icon('web-moitoring'),
        name: 'View-Web-Monitoring',
        submenu: [
            {
                title: 'Cross Module',
                path: '/dashboard/cross-module',
                icon: icon('cross-module'),
                name: 'Create-General-Url',
            },
            {
                title: 'Domains',
                path: '/dashboard/domains',
                icon: icon('website'),
                name: 'View-Domain',
            },
            {
                title: 'SSL',
                path: '/dashboard/ssl',
                icon: icon('ssl'),
                name: 'View-SSL',
            },
            {
                title: 'Uptime Monitor',
                path: '/dashboard/uptime-monitor',
                icon: icon('timer'),
                name: 'View-Uptime-Monitor',
            },
        ],
    },
    {
        title: 'Users Management',
        icon: icon('users-management'),
        name: 'View-Users-Management',
        submenu: [
            {
                title: 'Users',
                path: '/dashboard/users',
                icon: icon('ic_user'),
                name: 'View-Users',
            },
            {
                title: 'Roles',
                path: '/dashboard/roles',
                icon: icon('roles-permission'),
                name: 'View-Role',
            },
        ],
        },
    {
        title: 'DNS Checker',
        path: '/dashboard/dns-lookup',
        icon: icon('dns'),
        name: 'View-DNS-Checker',
    },
    {
        title: 'PageSpeed Insights',
        path: '/dashboard/pageSpeed',
        icon: icon('pageSpeed'),
        name: 'View-PageSpeed-Insights',
    },
    {
        title: 'Email Templates',
        path: '/dashboard/email-templates',
        icon: icon('email'),
        name: 'View-Email-Template',
    },
    {
        title: 'Log History',
        path: '/dashboard/logs',
        icon: icon('list'),
        name: 'View-Notification',
    },
    {
        title: 'Integration',
        path: '/dashboard/integration',
        icon: icon('integration'),
        name: 'View-Integration',
    },
    {
        title: 'Report Generate',
        path: '/dashboard/report',
        icon: icon('report'),
        name: 'View-Integration',
    },
    {
        title: 'Setting',
        path: '/dashboard/setting',
        icon: icon('settings'),
        name: 'Create-Settings',
    },

];

export default navConfig;
