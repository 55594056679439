import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    CardActions,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';

/**
 * The `MacAddressLookup` component allows users to perform a lookup of MAC addresses, OUI (Organizationally
 * Unique Identifier), or vendor names. Users can enter a MAC address or related information, and the component
 * will display detailed information about the address prefix, vendor/company, address range, and company address.
 *
 * This component offers an intuitive user interface with Material-UI styling and provides an option to copy
 * the results. Users can also navigate back to the dashboard from this component.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `MacAddressLookup` component.
 */
const MacAddressLookup = () => {
    /**
     * Design a schema for creating `MacAddressLookup` with form validations.
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     */
    const schema = yup.object().shape({
        macAddress: yup
            .string()
            .required('MAC Address is Required')
            .matches(
                /^([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})$/,
                'Invalid MAC Address Format. Please Enter a Valid MAC Address, e.g., 00:15:5d:86:eb:5f.'
            ),
    });

    // Initialization and state management
    const [macAddress, setMacAddress] = useState([]);
    const [ipAddress, setIpAddress] = useState();
    const [loader, setLoader] = useState(false);
    const authToken = JSON.parse(localStorage.getItem('token'));
    const url = useLocation();
    const macAddressPath = url.pathname;

    // They are part of the schema, form management and validation logic for MacAddressLookup
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles MacAddressLookup  upon form submission
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for MacAddressLookup.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 6 Oct, 2023
     *
     */
    const handleSubmitForm = async (data) => {
        setLoader(true);
        setIpAddress(data?.macAddress);
        const { macAddress } = data;
        try {
            const response = await api.get(`${ENV.appBaseUrl}/dns/mac-lookUp?macAddress=${macAddress}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
                },
            });
            if (response?.data?.success) {
                toast.success(response?.data?.message);
                setMacAddress(response?.data?.results);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response?.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    return (
        <>
            <div className="padding_px">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom className="ml-10">
                            <Link to="/dashboard">
                                <Button className="back_btn" variant="contained">
                                    <Iconify icon="bi:arrow-left" />
                                </Button>
                            </Link>
                            MAC Address Lookup
                            <div className="mt-10">
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to="/dashboard" className="domain_bread">
                                        Dashboard
                                    </Link>
                                    <Link to="/dashboard/mac-lookup" className="domain_bread">
                                        MAC Address Lookup
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </Typography>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <Card sx={{ p: 2 }}>
                                <CardContent>
                                    <TextField
                                        {...register('macAddress')}
                                        id="outlined-basic"
                                        label="Enter MAC Address or OUI or Vendor Name"
                                        placeholder="Example: 00-1B-63-84-45-E6"
                                        variant="outlined"
                                        sx={{ marginRight: '8px', width: '100%' }}
                                        type="text"
                                        error={!!errors?.macAddress}
                                        helperText={errors.macAddress?.message}
                                    />
                                </CardContent>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '16px',
                                        marginRight: '22px',
                                    }}
                                >
                                    <Stack spacing={2} direction="row">
                                        <Button variant="contained" type="submit" color="primary">
                                            Search
                                        </Button>
                                    </Stack>
                                </div>
                            </Card>
                            <br />
                        </form>
                    </Grid>
                </Grid>
                <Tools macAddressPathName={macAddressPath} />
                {loader ? (
                    <div
                        className="spinner"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '160px',
                        }}
                    >
                        <CircularProgress value={100} />
                    </div>
                ) : macAddress && macAddress?.length > 0 ? (
                    <div className="box_container overflow-auto my-4" id="ml_results">
                        {macAddress?.map((mac, index) => (
                            <table key={index} className="table mb-0 w-full">
                                <thead>
                                    <tr>
                                        <th
                                            colSpan="5"
                                            className="border-0 text-left"
                                            style={{ paddingTop: '0px !important' }}
                                        >
                                            <span className="h5 text-dark">Result for: </span>
                                            <h2 className="h5 mt-2 text-uppercase text_blue text-start d-inline-block searched_value">
                                                {ipAddress}
                                            </h2>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="">
                                            <strong>Address Prefix</strong>
                                        </td>
                                        <td className="text-uppercase color_td">
                                            {mac.hex}
                                            <i
                                                className="copy_result icon icon-copy-outline icon-black icon-lg-2"
                                                title="copy"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            <strong>Vendor / Company</strong>
                                        </td>
                                        <td className="text-capitalize color_td">
                                            {mac.name}
                                            <i
                                                className="copy_result icon icon-copy-outline icon-black icon-lg-2"
                                                title="copy"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            <strong>Start Address</strong>
                                        </td>
                                        <td className="text-uppercase color_td">
                                            {`${mac.b16}000000`}
                                            <i
                                                className="copy_result icon icon-copy-outline icon-black icon-lg-2"
                                                title="copy"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            <strong>End Address</strong>
                                        </td>
                                        <td className="text-uppercase color_td">
                                            {`${mac.b16}ffffff`}
                                            <i
                                                className="copy_result icon icon-copy-outline icon-black icon-lg-2"
                                                title="copy"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            <strong>Company Address</strong>
                                        </td>
                                        <td className="text-capitalize color_td">
                                            {mac.address}
                                            <i
                                                className="copy_result icon icon-copy-outline icon-black icon-lg-2"
                                                title="copy"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default MacAddressLookup;
