import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    CardActions,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/iconify';
import Tools from './Tools';
import api from '../../config/axios-instance';
import { ENV } from '../../config/config';

/**
 * The `WebsiteOS` component allows users to check the server operating system (OS) of a given website or IP address.
 * Users can input a domain or IP, and the component displays the server OS information. It provides a user-friendly
 * Material-UI interface for easy navigation and includes a back button to return to the dashboard.
 *
 * Author: Ali Haider
 * Date: 07 Sep, 2023
 *
 * @returns {JSX.Element} The JSX representation of the `WebsiteOS` component.
 */
const WebsiteOS = () => {
    /**
     * Design a schema for creating `WebsiteOS` with form validations.
     *
     * Author: Muhammad Rooman
     * Date: 9 Oct, 2023
     */
    const schema = yup.object().shape({
        url: yup.string().required('Domain/IP is Required'),
            // .url('Invalid URL Format. Please Enter a Valid URL in the Following Format: https://example.com.')
            // .test('No-Trailing-Slash', 'URL Should Not End With a Slash ("/")', (value) => {
            //     if (value && value.endsWith('/')) {
            //         return false;
            //     }
            //     return true;
            // }),
    });

    // Initialization and state management
    const [websiteOS, setWebsiteOS] = useState([]);
    const [websiteName, setWebsiteName] = useState();
    const authToken = JSON.parse(localStorage.getItem('token'));
    const [loader, setLoader] = useState(false);
    const url = useLocation();
    const websiteOperationgPath = url.pathname;

    // They are part of the schema, form management and validation logic for WebsiteOS
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /**
     * Handles WebsiteOS upon form submission
     * Sends a GET request to retrieve records for a given domain.
     *
     * @param {object} data - The data containing the domain for WebsiteOS.
     * @return {void}
     *
     * Author: Muhammad Rooman
     * Date: 9 Oct, 2023
     *
     */
    const handleSubmitForm = async (data) => {
        setLoader(true);
        setWebsiteName(data.url);
        const { url } = data;
        try {
            const response = await api.get(`${ENV.appBaseUrl}/dns/web-server-info?url=${url}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
                },
            });
            if (response?.data?.success) {
                setWebsiteOS(response.data.headers);
                toast.success(response.data?.message);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data?.message);
            }
        } catch (error) {
            setLoader(false);
            toast.error(error?.response.data?.message);
        }
    };

    return (
        <div className="padding_px">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" gutterBottom className="ml-10">
                        <Link to="/dashboard">
                            <Button className="back_btn" variant="contained">
                                <Iconify icon="bi:arrow-left" />
                            </Button>
                        </Link>
                        Website OS
                        <div className="mt-10">
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/dashboard" className="domain_bread">
                                    Dashboard
                                </Link>
                                <Link to="/dashboard/website-server-software" className="domain_bread">
                                    Website OS
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Card sx={{ p: 2 }}>
                            <CardContent>
                                <TextField
                                    {...register('url')}
                                    id="outlined-basic"
                                    label="Domain/IP"
                                    placeholder="Enter URL or IP"
                                    variant="outlined"
                                    sx={{ marginRight: '8px', width: '100%' }}
                                    type="text"
                                    error={!!errors?.url}
                                    helperText={errors.url?.message}
                                />
                            </CardContent>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '16px',
                                    marginRight: '22px',
                                }}
                            >
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" type="submit" color="primary">
                                        Check Server OS
                                    </Button>
                                </Stack>
                            </div>
                        </Card>
                        <br />
                    </form>
                </Grid>
            </Grid>
            <Tools websiteOperationgPathName={websiteOperationgPath} />
            {loader ? (
                <div
                    className="spinner "
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '160px' }}
                >
                    <CircularProgress value={100} />
                </div>
            ) : websiteOS && websiteOS.server ? (
                <div className="box_container my-4" id="wss_results">
                    <h2 className="result_heading_style h3">
                        Results for <span className="text_blue">{websiteName}</span>
                    </h2>
                    <div className="apache_bg">{websiteOS.server}</div>
                </div>
            ) : null}
        </div>
    );
};

export default WebsiteOS;
