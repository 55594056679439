import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import { ENV } from '../../../config/config';

/**
 * Fetch User Profile data by ID.
 *
 * @param {Object} param0 - Contains userId and token.
 * @param {string} param0.userId - The ID of the user to fetch.
 * @param {string} param0.token - The authorization token.
 * @param {Object} thunkAPI - Thunk API object.
 * @param {Function} thunkAPI.rejectWithValue - Function to handle rejected values.
 * @return {Promise<Object>} The user data.
 *
 * Author: Muhammad Rooman
 * Date: 1 August, 2024
 */
export const fetchUserProfileData = createAsyncThunk(
  'userProfile/fetchData',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ENV.appBaseUrl}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/**
 * Update the profile image of the user.
 *
 * @param {FormData} formData - The form data containing the image.
 * @param {Object} thunkAPI - Thunk API object.
 * @param {Function} thunkAPI.rejectWithValue - Function to handle rejected values.
 * @return {Promise<Object>} The updated user data.
 *
 * Author: Muhammad Rooman
 * Date: 1 August, 2024
 */
export const editUserProfileImage = createAsyncThunk(
  'userProfile/editImage',
  async (formData, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    try {
      const response = await axios.put(`${ENV.appBaseUrl}/user/edit-profile-image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dataSlice = createSlice({
  name: "userProfile",
    initialState: {
    user: null,
    userImage: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // Fetch user data by ID
      .addCase(fetchUserProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
       state.userImage = action.payload.profileImage;
      })
      .addCase(fetchUserProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || action.error.message;
      })
      // Update profile image
      .addCase(editUserProfileImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editUserProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.userImage = action.payload.profileImage;
        toast.success("Profile image updated successfully");
      })
      .addCase(editUserProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || action.error.message;
        toast.error(action.payload?.message || action.error.message);
      });
  },
});

export default dataSlice.reducer;
